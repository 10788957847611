<template>
    <el-drawer :visible="!!task"
               class="right-side-dialog right-side-dialog__without-padding with-el-drawer-custom-scrollbar"
               :size="size"
               :before-close="close"
               :show-close="false"
               :modal="modal">
        <!-- Header -->
        <template slot="title">
            <div class="f-v-center f-x-between _px-1">
                <!-- Title -->
                <div class="f space-x-0.5">
                    <!-- Label -->
                    <is-label :value="labels.aboutTask"
                              primary />

                    <!-- Status -->
                    <task-status v-if="viewedTask"
                                 :task="viewedTask" />
                </div>

                <!-- Actions -->
                <div class="f-v-center space-x-1">
                    <!-- Open -->
                    <el-button type="text"
                               @click="goToViewedTask">
                        <div class="f-y-center space-x-0.5">
                            <icon name="link"
                                  class="wh-1"
                                  color="accent" />
                            <p class="fs-0.8">
                                {{ labels.openTask }}
                            </p>
                        </div>
                    </el-button>

                    <!-- Close -->
                    <icon name="cross"
                          color="gray-600"
                          class="wh-2 cursor-pointer"
                          @click="close" />
                </div>
            </div>
        </template>

        <!-- Body -->
        <div v-loading="loading"
             :class="classes.body">
            <component :is="taskComponent"
                       v-if="viewedTask"
                       :task="viewedTask"
                       :allowed-actions="allowedActions"
                       dependent
                       @close="close" />
        </div>
    </el-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { openNew } from '@/utils/browser'

import { loadable } from '@/store/connectors'

import { types, translateType } from '@/models/tasks'

import TaskStatus from '@/components/tasks/TaskStatus'
import TaskTypeDefectsAndViolations from '@/components/task/TaskTypeDefectsAndViolations'
import TaskTypeAcceptanceOfWork from '@/components/task/TaskTypeAcceptanceOfWork'
import TaskTypeBuildingOrder from '@/components/task/TaskTypeBuildingOrder'
import TaskTypePhotoOrder from '@/components/task/TaskTypePhotoOrder'
import TaskTypeApprovalProcess from '@/components/task/TaskTypeApprovalProcess'

const labels = {
  aboutTask: 'Детали задачи',
  openTask: 'Открыть задачу в новом окне'
}

export default {
  components: {
    TaskStatus
  },
  mixins: [
    loadable({ on: 'tasks', name: 'showTask' })
  ],
  props: {
    task: { type: Object, default: null },
    modal: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters('tasks', ['allowedActions']),
    ...mapGetters('tasks', {
      viewedTask: 'task'
    }),

    loading() {
      return this.showTaskLoading
    },

    labels() {
      return {
        ...labels,
        aboutTask: this.task ? `${translateType(this.task.type, { capital: true })} №${this.task.number_in_project}` : labels.aboutTask
      }
    },

    classes() {
      return {
        body: {
          '_p-1': true,
          'h-full': !this.viewedTask
        }
      }
    },

    taskComponent() {
      const { type } = this.task || {}

      let r

      r ||= type === types.DEFECTS_AND_VIOLATIONS && TaskTypeDefectsAndViolations
      r ||= type === types.ACCEPTANCE_OF_WORK && TaskTypeAcceptanceOfWork 
      r ||= type === types.BUILDING_ORDER && TaskTypeBuildingOrder
      r ||= type === types.PHOTO_ORDER && TaskTypePhotoOrder
      r ||= type === types.APPROVAL_PROCESS && TaskTypeApprovalProcess

      return r
    },

    size() {
      const { type } = this.task || {}

      let r

      r ||= type === types.APPROVAL_PROCESS && '700px'
      r ||= '640px'

      return r
    }
  },
  watch: {
    task() {
      this.fetch()
    }
  },
  methods: {
    ...mapActions('tasks', ['showTask', 'deselectTask']),

    fetch() {
      const { type } = this.task || {}

      this.task && this.showTask({
        taskId: this.task.id,
        payload: {
          params: {
            include: [
              'project',
              'creator',
              'children',
              'members.user.organization',
              'memberInitiator.user.organization',
              'memberInspectors.user.organization',
              'memberWorkers.user.organization',
              'defectGeneralContractor.organization',
              'acceptanceOfWorkContractor.organization',
              'objectsBuildingOrderConnection.object',
              'objectsAcceptanceOfWorkConnection.object',
              'subjectsBuildingOrderConnection.subject',
              'subjectsAcceptanceOfWorkConnection.subject',
              'linkedDefectProjectObjects'
            ],
            append: [
              'checking_act_template_with_values', 
              'files_count',
              type === types.ACCEPTANCE_OF_WORK && 'last_message_with_creator',
              type === types.ACCEPTANCE_OF_WORK && 'messages_count_all'
            ].filter(is)
          }
        }
      })
    },

    goToViewedTask() {
      const { id, type } = this.task || {}

      let r

      r ||= type === types.APPROVAL_PROCESS && this.$router.resolve({ name: 'project.approval.info', params: { processId: id } })
      r ||= this.$router.resolve({ name: 'project.task.info', params: { taskId: id } })

      const { href } = r || {}

      href && openNew(href)
    },

    close() {
      this.deselectTask()
      this.$emit('close')
    }
  }
}
</script>
