<template>
    <fieldable v-loading="loading"
               :label="label"
               :name="name"
               :required="required"
               :errors="errors"
               :no="independent"
               :dark="dark">
        <!-- Label -->
        <template #label>
            <slot name="label" />
        </template>

        <!-- Selector -->
        <el-select :value="value"
                   :placeholder="placeholder"
                   :value-key="asKey ? null : valueKey"
                   :multiple="multiple"
                   :disabled="disabled"
                   :class="classes.selector"
                   :size="size"
                   :clearable="clearable"
                   :filterable="filterable"
                   :remote-method="search"
                   @change="change">
            <template #prefix>
                <slot name="prefix" />
            </template>
            <el-option v-for="(option, i) in options"
                       :key="option[valueKey]"
                       :label="numerable ? `${i + 1}. ${option[labelKey]}` : option[labelKey]"
                       :value="asKey ? option[valueKey] : option"
                       :disabled="option.disabled">
                <slot name="option" 
                      :option="option" />
            </el-option>
        </el-select> 

        <!-- Actions -->
        <div>
            <el-button v-if="withActions"
                       type="text"
                       @click="toggleAll">
                {{ labels.selection }}
            </el-button>
        </div>
    </fieldable>
</template>

<script>
import Fieldable from '@/components/fields/Fieldable.vue'

const labels = {
  placeholder: 'Выбрать',
  fill: 'Выбрать всё',
  clear: 'Очистить выбор'
}

export default {
  components: {
    Fieldable
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: [Array, Object, String], default: null },
    options: { type: Array, default: () => [] },
    name: { type: String, default: null },
    label: { type: String, default: null },
    independent: { type: Boolean, default: false },
    placeholder: { type: String, default: labels.placeholder },
    valueKey: { type: String, default: 'value' },
    labelKey: { type: String, default: 'label' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    filterable: { type: Boolean, default: false },
    numerable: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    asKey: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    size: { type: String, default: null },

    selectAll: { type: Boolean, default: false },
    selectFirst: { type: Boolean, default: false },

    search: { type: Function, default: null },

    withActions: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        selector: {
          'w-full': true,
          'with-el-select-readonly': this.readonly
        }
      }
    },

    items() {
      return this.options
    },

    labels() {
      return {
        ...labels,
        selection: this.value?.length ? labels.clear : labels.fill
      }
    }
  },
  watch: {
    items: {
      handler(x) {
        this.selectAll && this.change(x)
        this.selectFirst && this.change(x[0])
      },
      immediate: true
    }
  },
  methods: {
    change(x) {
      this.$emit('change', x)
    },

    toggleAll() {
      this.change(this.value.length ? [] : this.options)
    }
  }
}
</script>
