export default axios => ({
  index(payload) {
    return axios.get('/tech-rooms', payload);
  },
  store(payload) {
    return axios.post('/tech-rooms', payload);
  },
  show(id, payload = null) {
    return axios.get(`/tech-rooms/${id}`, payload);
  },
  update(id, payload) {
    return axios.patch(`/tech-rooms/${id}`, payload);
  },
  destroy(id) {
    return axios.delete(`/tech-rooms/${id}`);
  },

  getFloorPlanTechRooms(floorPlanId, payload) {
    return axios.get(`floor-plans/${floorPlanId}/tech-rooms`, payload).then(({ data }) => data.data)
  }
})
