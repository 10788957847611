export default (axios) => ({
  getInfo: ({ approvalProcessId }) => {
    return axios.get(`approvals/${approvalProcessId}`);
  },
  update: (approvalProcessId, payload) => {
    return axios.put(`approvals/${approvalProcessId}/change-status`, payload);
  },
  initProcess: (approvalProcessId, payload) => {
    return axios.put(`approvals/${approvalProcessId}`, payload);
  },
  createProcess: (payload) => {
    return axios.post('approvals', payload);
  },
  deleteProcess: (approverId, processId) => {
    return axios.delete(`approvals/${processId}`, {
      data: {
        'approver_id': approverId
      }
    });
  },
  comment: (payload) => {
    return axios.post('comments', payload);
  },
  putCommentFile: (commentFile) => {
    let formData = new FormData();
    formData.append('file', commentFile);

    return axios.put('comment-files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  downloadCommentFile: (fileId) => axios.get(`comment-files/${fileId}`, {responseType: 'blob', headers: {
    Accept: 'application/octet-stream'
  }}),
  getProcesses: () => {
    return axios.post('approvals/get-all', {});
  },
  getProcessById: (processId) => {
    return axios.get(`approvals/${processId}`);
  },
  resumeProcess: (processId, payload) => {
    return axios.put(`approvals/${processId}/resume`, payload);

  },
  getReportKpiFile: ({ project }) => axios.get(`reports/kpi/${project.id}`, {
    responseType: 'blob'
  }),
  izmProcess: (processId, payload) => {
    return axios.put(`approvals/${processId}/restart-with-changes`, payload);
  }
});
