<template>
    <el-radio-group v-model="pageTab"
                    class="navigation__tabs"
                    size="mini">
        <el-radio-button v-for="{ key, label, name } in tabs"
                         :key="key"
                         class="navigation__tabs__item"
                         :label="label">
            {{ name }}
        </el-radio-button>
    </el-radio-group>
</template>

<script>
import { translateTreeTypes, treeTypes } from '@/models/documents';

export default {
  props: {
    treeType: { type: String, default: treeTypes.TREE_BY_APPROVAL, require: false }
  },
  data() {
    return {
      pageTab: this.treeType
    }
  },
  computed: {
    tabs() {
      return Object.entries(treeTypes).map(([key, value]) => ({ key, label: value, name: translateTreeTypes(value) }))
    }
  },
  watch: {
    pageTab (tab) {
      this.$emit('change-tree-type', tab)
    }
  }
}
</script>
<style lang="scss">
.navigation {
  .el-radio-group {
    display: flex;
  }

  &__tabs {
    margin: 8px 16px;
    box-sizing: border-box;
    
    &__item {
      width: 100%;

      .el-radio-button__inner {
        width: 100%;
      }
    }
  }
}
</style>
