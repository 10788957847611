<template>
    <div :class="classes.root"
         @click="click">
        <!-- Body -->
        <div :class="classes.body">
            <!-- Icon -->
            <div class="f-center">
                <icon v-if="withIcon"
                      :name="icon"
                      :class="classes.icon" />
            </div>

            <!-- About -->
            <div :class="classes.about">
                <div class="f space-x-0.5">
                    <our-label :value="nameResult" />
                    <tag v-if="withVersion && hasVersion"
                         :label="version" />
                </div>
                <our-label v-if="withType"
                           :value="type"
                           tertiary />
                <slot name="header" />
            </div>
        </div>

        <!-- Extra -->
        <slot />
    </div>
</template>

<script>
import { getName, getType, getIcon, getVersion } from '@/models/documents'

export default {
  props: {
    document: { type: Object, default: () => ({}) },
    name: { type: String, default: null },
    size: { type: String, default: 'default' },
    withIcon: { type: Boolean, default: false },
    withVersion: { type: Boolean, default: false },
    withType: { type: Boolean, default: false },
    canDownload: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        root: {
          '_py-0.25 _pr-0.25': true,
          'download': this.canDownload
        },
        body: {
          'cols-min-minmax rows-min': true
        },
        about: {
          '_pl-0.25 cols-1': true,
          'rows-2': this.withType,
          'rows-1': !this.withType
        },
        icon: {
          'no-shrink border-box': true,
          'wh-3 _p-0.25': this.size === 'default',
          'wh-2 _p-0.25': this.size === 'mini'
        }
      }
    },

    styles() {
      return {}
    },

    nameResult() { return this.name || getName(this.document) },
    type() { return getType(this.document) },
    icon() { return getIcon(this.document) },
    version() { return `V${getVersion(this.document)}` },

    hasVersion() { return getVersion(this.document) }
  },
  methods: {
    click() {
      this.$emit('click', this.document)
    }
  }
}
</script>

<style lang="scss">
.download {
  &:hover {
    cursor: pointer;
    
    .label p {
      color: #409EFF;
    }
  }
}
</style>
