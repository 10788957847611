<template>
    <div class="f-y-center">
        <div class="f h-3">
            <!-- Logo -->
            <router-link :to="{ name: 'projects' }"
                         class="w-3 h-full _p-0.5 _px-0.5 border-box no-grow">
                <logo />
            </router-link>

            <!-- Project -->
            <project-field 
                v-if="projectName"
                v-model="selectedProject"
                class="w-15"
                select-current
                with-address
                with-address-option
                with-address-tooltip-value
                with-avatar
                independent
                native
                dark
                @change="goToProject" />

            <!-- Divider -->
            <div v-if="projectName"
                 class="_py-0.5">
                <div class="h-full border-left-1 border-gray-700" />
            </div>
        </div>

        <!-- Menu -->
        <el-menu :default-active="$route.name"
                 mode="horizontal"
                 background-color="#333745"
                 text-color="#fff"
                 class="navigation-menu"
                 router
                 active-text-color="#409EFF">
            <template v-for="(item, parentIndex) in routes">
                <el-menu-item v-if="hasItemAccess(item) && isRootShow(item)"
                              :key="parentIndex"
                              :index="`${item.path}`"
                              :route="{ name: `${item.name}` }">
                    {{ item.meta.title }}
                </el-menu-item>

                <el-submenu v-if="hasItemAccess(item) && isGroupShow(item)"
                            :key="parentIndex"
                            :index="`${parentIndex + 1}`">
                    <template slot="title">
                        {{ item.meta.title }}
                    </template>

                    <!-- To Tableau -->
                    <template v-if="projectName && item.name === 'management'">
                        <el-menu-item index=""
                                      :no-access-class="'no-access--menu'"
                                      :disabled="!projectTableau"
                                      @click="goToTableau">
                            Панель управления строительством ↪
                        </el-menu-item>
                    </template>

                    <template v-if="item.children.length > 0">
                        <access v-for="(child, index) in item.children"
                                :key="index"
                                :no-access-class="'no-access--menu'"
                                :settings="extractItemAccess(child).settings"
                                :permissions="extractItemAccess(child).permission"
                                :licence-abilities="extractItemAccess(child).licenceAbility">
                            <el-menu-item v-show="!child.meta.hidden"
                                          :index="`${child.name}`"
                                          :route="{ name: `${child.name}` }"
                                          class="f-x-between f-y-center">
                                {{ child.meta.title }}

                                <el-tag v-if="countByItemName(child.name)"
                                        size="mini"
                                        type="danger"
                                        effect="dark">
                                    {{ countByItemName(child.name) }}
                                </el-tag>
                            </el-menu-item>
                        </access>
                    </template>
                </el-submenu>
            </template>
        </el-menu>

        <div class="spacer" />

        <!-- Actions -->
        <div v-if="hasProject && false"
             class="f-v-center _ml-1">
            <el-dropdown placement="bottom-start">
                <icon name="plus"
                      color="white"
                      class="wh-2.5 _p-0.6 border-box cursor-pointer" />

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>{{ labels.inviteUser }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <el-menu
            mode="horizontal"
            background-color="#333745"
            text-color="#fff"
            class="navigation-menu">
            <el-menu-item @click="goToSupport">
                {{ labels.support }}
            </el-menu-item>
        </el-menu>


        <!-- Notification -->
        <div class="f-center _px-0.5 cursor-pointer hover:bg-el-dark-hover"
             style="height: 60px"
             @click="goToNotifications">
            <el-badge :value="unreadNotificationCountRestricted"
                      :hidden="!unreadNotificationCount"
                      class="as-el-badge-sm depth-10">
                <icon name="bell"
                      class="wh-1.5"
                      color="gray-400" />
            </el-badge>
        </div>

        <!-- Profile -->
        <el-menu v-if="profile"
                 mode="horizontal"
                 :default-active="$route.name"
                 background-color="#333745"
                 text-color="#fff"
                 class="navigation-menu"
                 router
                 active-text-color="#409EFF">
            <el-submenu index="2">
                <template slot="title">
                    <el-avatar :size="25"
                               class="navigation-menu__profile-icon"
                               icon="el-icon-user" />
                    {{ profile.name }}
                </template>
                <el-menu-item index="lk.profile"
                              :route="{ name: 'lk.profile' }">
                    <i class="el-icon-user" />Личный кабинет
                </el-menu-item>
                <el-menu-item @click="handleClickGoToDocumentation">
                    <i class="el-icon-info" />Документация
                </el-menu-item>
                <el-divider direction="horizontal"
                            class="my-1" />
                <el-row type="flex"
                        justify="center">
                    <el-col class="el-menu-item menu-button">
                        <el-button type="text"
                                   @click="logout">
                            <i class="el-icon-switch-button" />
                            <div class="mx-0 text-size--body d-inline-flex">
                                Выход
                            </div>
                        </el-button>
                    </el-col>
                </el-row>
            </el-submenu>
        </el-menu>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { CONTACT_EMAIL } from '@/utils/helper'
import { mailTo } from '@/utils/browser'

import { resourceable, actionable } from '@/store/connectors'

import Logo from '@/components/icons/header/Logo'
import ProjectField from '@/components/fields/ProjectField'

const labels = {
  inviteUser: 'Пригласить пользователя',
  lackOfBuildingReadiness: 'Нет строительной готовности',
  support: 'Поддержка'
}

export default {
  components: {
    Logo,
    ProjectField
  },
  mixins: [
    resourceable({ on: 'account', name: 'unreadNotificationCount' }),
    resourceable({ on: 'payments', name: 'paymentsCount', permissions: ['system_organizations_edit'], mounted: true }),

    actionable({ on: 'account', name: 'applyUnreadNotificationCount' })
  ],
  data: () => ({
    selectedProject: null,

    menu: [
      {
        title: 'Главная',
        childrens: [
          {
            icon: 'el-icon-picture-outline',
            title: 'Все изображения',
            link: 'photos.all'
          }
        ]
      },
      {
        title: 'Управление',
        childrens: [
          {
            icon: 'el-icon-s-order',
            title: 'ML',
            link: 'management.ml.versions'
          },
          {
            icon: 'el-icon-s-order',
            title: 'ML-Dashboard',
            link: 'management.ml.dashboard'
          },
          {
            icon: 'el-icon-s-order',
            title: 'Пользователи',
            link: 'management.users',
            permissionName: 'Просмотр Пользователей'
          }
          // {
          //   icon: 'el-icon-s-order',
          //   title: 'Роли',
          //   link: 'management.roles',
          //   permissionName: 'Просмотр Ролей',
          // },
        ]
      }
    ],
    enableNotificationLackConstructionReadiness: false,
    labels
  }),
  computed: {
    ...mapGetters({
      permissions: 'auth/permissions',
      routes: 'access/routes',
      projectName: 'project/projectName',
      projectTableau: 'project/projectTableau'
    }),
    ...mapState('auth', ['profile']),
    ...mapGetters('project', ['projectLoaded']),
    ...mapGetters('account', ['hasAccess']),
    ...mapGetters('auth', ['profileId']),

    projectId() {
      return this.$route.params.projectId
    },

    hasProject() {
      return this.projectLoaded
    },

    unreadNotificationCountRestricted() {
      return this.unreadNotificationCount > 99 ? '99+' : this.unreadNotificationCount + ''
    },

    paymentsCountRestricted() {
      return this.paymentsCount > 99 ? '99+' : this.paymentsCount
    },

    countByItemName() {
      return x => ({
        'management.payments': this.paymentsCountRestricted
      })[x]
    }
  },
  mounted() {
    this.subscribe()
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    subscribe() {
      this.$socket.echoClient.private(`v1.users.${this.profileId}`)
        .listen('.user-event-notifications.unread-count', ({ count }) => {
          this.applyUnreadNotificationCount({ count })
          // dialogs.aboutNotification.call(this)
        })
        .listen('.user-event-notifications.read', () => {
          this.fetchUnreadNotificationCount()
        })
    },

    unsubscribe() {
      this.$socket.echoClient.leave(`v1.users.${this.profileId}`)
    },

    goToProject({ id } = {}) {
      const prev = this.$route.params.projectId

      id && prev !== id && this.$router.replace({ params: { projectId: id } })
      id && prev !== id && this.$router.go(this.$router.currentRoute)
    },

    goToNotifications() {
      this.$router.push({ name: 'lk.notifications' })
    },

    goToTableau () {
      window.open(this.projectTableau, '_blank')
    },

    goToSupport() {
      mailTo(CONTACT_EMAIL)
    },

    // NOT REVIEWED

    handleClickGoToDocumentation() {
      window.open('https://help.terra360.ru')
    },
    notifyLackOfBuildingReadiness() {
      this.$router.push({ name: 'project.notify-lack-building-readiness' })
    },
    async logout() {
      await this.$store.dispatch('auth/LOGOUT');
      this.$router.push({ name: 'auth.signin' });
    },
    isGroupShow(navigationItem) {
      if (navigationItem.name === 'acs') {
        return this.$route.params.projectId === process.env.VUE_APP_DEMO_PROJECT_ID || (this.$route.name === 'acs.dashboard' || this.$route.name === 'acs.report-page');
      } else {
        return navigationItem.meta && navigationItem.meta.topNavigation && navigationItem.meta.navigationGroup && navigationItem.children.length > 0
      }
    },
    isRootShow(navigationItem) {
      return navigationItem.meta && navigationItem.meta.topNavigation && !navigationItem.meta.navigationGroup;
    },

    extractItemAccess(item) {
      return item.meta.access || {}
    },

    hasItemAccess(item) {
      return this.hasAccess(item.meta.access || {})
    }
  }
};
</script>
<style lang="scss">
.el-menu-item {
  &.menu-button {
    background-color: #333745 !important;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: white;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.el-button {
  font-size: 12px;
  padding: 6px, 10px, 6px, 10px;
}
</style>
