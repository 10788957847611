<template>
    <fieldable :label="label"
               :name="name"
               :errors="errors"
               :required="required"
               :no="independent">
        <!-- Label -->
        <template #label>
            <slot name="label" />
        </template>

        <!-- Selector -->
        <el-select v-loading="loading"
                   :value="value"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   :remote-method="search"
                   :loading="loading"
                   :multiple="multiple"
                   :value-key="asKey ? null : 'id'"
                   size="mini"
                   class="w-full"
                   remote
                   filterable
                   clearable
                   @input="change"
                   @clear="clear">
            <!-- Options -->
            <el-option-group v-for="group in groups"
                             :key="group.id"
                             :label="group.label">
                <!-- Organizations -->
                <el-option v-for="organization in group.organizations"
                           :key="organization.id"
                           :label="organization.name"
                           :value="organization"
                           :class="classes.organizationOption(organization)">
                    <div class="f-x-between f-y-center">
                        <span>{{ organization.name }}</span>
                        <div class="space-x-0.5 _pl-0.5">
                            <organization-blocking-tag :organization="organization" />
                            <span class="fs-0.8"
                                  style="color: #8492a6">{{ organization._size }}</span>
                        </div>
                    </div>
                </el-option>

                <!-- Users -->
                <el-option v-for="user in group.users"
                           :key="user.id"
                           :label="toLabel(user)"
                           :value="asKey ? user.id : user"
                           :class="classes.userOption(user)">
                    <div class="f-x-between f-y-center">
                        <span>{{ toUserName(user) }}</span>
                        <div class="space-x-0.5 _pl-0.5">
                            <user-blocking-tag :user="user" />
                            <span v-if="user.organization && user.organization.name"
                                  class="fs-0.8"
                                  style="color: #8492a6">{{ user.organization.name }}</span>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
        </el-select>
    </fieldable>
</template>

<script>
import withUsersFetcher from '@/mixins/withUsersFetcher'

import { getName, getOrganizationName, groupUsersByOrganization } from '@/models/users'

import Fieldable from '@/components/fields/Fieldable'
import UserBlockingTag from '@/components/users/UserBlockingTag'
import OrganizationBlockingTag from '@/components/organizations/OrganizationBlockingTag'

const labels = {
  name: 'Пользователь',
  users: 'Пользователи',
  organizations: 'Организации'
}

export default {
  components: {
    Fieldable,
    UserBlockingTag,
    OrganizationBlockingTag
  },
  mixins: [
    withUsersFetcher
  ],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: [Object, String, Array], default: null },
    items: { type: Array, default: null },
    name: { type: String, default: null },
    label: { type: String, default: labels.name },
    placeholder: { type: String, default: 'Выбрать' },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },

    independent: { type: Boolean, default: false },

    asKey: { type: Boolean, default: false },

    isTaskCreator: { type: Boolean, default: false },
    isContractor: { type: Boolean, default: false },
    isEngineer: { type: Boolean, default: false },
    isGeneralEngineer: { type: Boolean, default: false },
    isInspector: { type: Boolean, default: false },
    isPhotographer: { type: Boolean, default: false },
    isManager: { type: Boolean, default: false },
    isWorkPlanAdministrator: { type: Boolean, default: false },
    isWorkPlanSupervisor: { type: Boolean, default: false },
    isWorkPlanForeman: { type: Boolean, default: false },
    isProjectDesigner: { type: Boolean, default: false },
    isChiefProjectDesigner: { type: Boolean, default: false },
    isDocumentApprover: { type: Boolean, default: false },

    selectAll: { type: Boolean, default: false },

    withOrganizations: { type: Boolean, default: false },

    withDisabledSelection: { type: Boolean, default: true }
  },
  data() {
    return {
      users: [],
      searchQuery: '',
      loading: false
    }
  },
  computed: {
    classes() {
      return {
        organizationOption: organization => ({
          'opacity-0.5': organization?.blocked_at && !this.withDisabledSelection,
          'without-events': organization?.blocked_at && !this.withDisabledSelection
        }),
        userOption: user => ({
          'opacity-0.5': user.organization?.blocked_at && !this.withDisabledSelection,
          'without-events': user.organization?.blocked_at && !this.withDisabledSelection
        })
      }
    },

    groups() {
      return [
        this.withOrganizations && {
          label: labels.organizations,
          organizations: groupUsersByOrganization(this.users).map(x => ({
            ...x.organization,
            _size: x.users.length,
            _isOrganization: true
          }))
        },
        {
          label: this.withOrganizations ? labels.users : null,
          users: this.users.map(x => ({
            ...x,
            _isUser: true
          }))
        }
      ].filter(x => x).map(x => ({
        ...x,
        id: key()
      }))
    }
  },
  watch: {
    items(users) {
      this.apply(users)
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true

      is((this.items && Promise.resolve(this.items)) || this.createUsersFetcher({ 
        hasBeTaskCreator: this.isTaskCreator,
        hasBeTaskContractor: this.isContractor,
        hasBeTaskInspector: this.isInspector,
        hasBeTaskEngineer: this.isEngineer,
        hasBeTaskGeneralEngineer: this.isGeneralEngineer,
        hasBeTaskManager: this.isManager,
        hasBePhotographer: this.isPhotographer,
        hasBeWorkPlanAdministrator: this.isWorkPlanAdministrator,
        hasBeWorkPlanSupervisor: this.isWorkPlanSupervisor,
        hasBeWorkPlanForeman: this.isWorkPlanForeman,
        hasBeProjectDesigner: this.isProjectDesigner,
        hasBeChiefProjectDesigner: this.isChiefProjectDesigner,
        hasBeDocumentApprover: this.isDocumentApprover
      })({
        params: {
          filter: {
            search: this.searchQuery
          }
        }
      }))
        .then(this.apply.bind(this))
        .finally(() => this.loading = false)
    },

    apply(users) {
      this.users = users
      this.selectAll && this.change(this.asKey ? users.map(({ id }) => id) : users)
    },

    search(query) {
      this.searchQuery = query
      this.fetch()
    },

    clear() {
      this.searchQuery = ''
      this.fetch()
      this.$emit('change', this.multiple ? [] : null)
    },
    change(value) {
      this.$emit('change', value)
    },
    toLabel(user) {
      return getOrganizationName(user) + ' / ' + getName(user)
    },

    toUserName(user) {
      return getName(user)
    }
  }
}
</script>
