import { resourceable } from '@/store/mixins'

export default {
  namespaced: true,

  mixins: [
    resourceable({
      name: 'techRooms',
      from: ({ api, getters }, { planId, withBoundary } = {}) => 
        api.techRooms.getFloorPlanTechRooms(planId || getters['project/selectedPlan'].id, {
          params: {
            append: [
              withBoundary && 'boundary'
            ].filter(is)
          }
        })      
    })
  ],

  state: {
    roomSellStatuses: null,
    roomTypes: null
  },

  getters: {},

  mutations: {},

  actions: {
    getTechRooms: async function (context, payload = null) {
      let {data} = await this.$api.techRooms.index(payload);
      return data;
    },
    storeTechRoom: async function (context, {payload}) {
      let {data} = await this.$api.techRooms.store(payload);
      return data;
    },
    showTechRoom: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.techRooms.show(id, payload);
      return data;
    },
    updateTechRoom: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.techRooms.update(id, payload);
      return data;
    },
    destroyTechRoom: async function (context, payload) {
      let {data} = await this.$api.techRooms.destroy(payload);
      return data;
    },
    restoreTechRoom: async function (context, id) {
      let {data} = await this.$api.techRooms.restore(id);
      return data;
    }
  }
};

