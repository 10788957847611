import { fromError } from '@/utils/common';
import { loadable, actionable, resourceable } from '../mixins'

export default {
  namespaced: true,

  mixins: [
    actionable({
      name: 'storeMultiple',
      loadable: true,
      at: ({ api }, { house, number_start_from, floor_count }) => 
        api.floorsV2.storeMultiple({
          house,
          number_start_from,
          floor_count
        })
          .catch(fromError)
    }),

    resourceable({
      name: 'floorPlans',
      from: ({ api }, { floorId, withImage, withPoints, withPointsImage }) => 
        api.floors.getFloorPlans(floorId, {
          params: {
            include: [
              withImage && 'plan',
              withPoints && 'definingPoints',
              withPointsImage && 'definingPoints.lastDefiningPointImage'
            ].filter(is)
          }
        }).then(x => x.data.data || [])
    }),

    loadable({ action: 'storeFloor' }),
    loadable({ action: 'updateFloor' }),
    loadable({ action: 'deleteFloor' }),
    loadable({ action: 'getFloorRooms' })
  ],

  state: {
    selectedFloorRooms: []
  },

  getters: {
    selectedFloorRooms: (state) => {
      return state.selectedFloorRooms.sort((a, b) => {
        return a.number - b.number;
      });
    }
  },

  mutations: {
    SET_SELECTED_FLOOR_ROOMS: (state, payload) => {
      state.selectedFloorRooms = payload;
    }
  },

  actions: {
    storeFloor: async function (context, {payload}) {
      let {data} = await this.$api.floors.storeFloor(payload);
      return data;
    },
    getFloor: async function (context, {floorId}) {
      let {data} = await this.$api.floors.getFloor(floorId);
      return data;
    },
    updateFloor: async function (context, {
      floorId,
      payload
    }) {
      let {data} = await this.$api.floors.updateFloor(floorId, payload);
      return data;
    },
    deleteFloor: async function (context, {
      floorId,
      payload
    }) {
      let {data} = await this.$api.floors.deleteFloor(floorId, payload);
      return data;
    },
    getFloorRooms: async function ({commit}, {
      floorId,
      payload
    }) {
      let {data} = await this.$api.floors.getFloorRooms(floorId, payload);
      commit('SET_SELECTED_FLOOR_ROOMS', data.data);
      return data;
    }
  }
};

