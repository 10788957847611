<template>
    <select-field :value="value"
                  :options="options"
                  class="color-field"
                  :label="label"
                  :name="name"
                  :required="required"
                  :disabled="disabled"
                  :errors="errors"
                  :independent="independent"
                  :placeholder="placeholder"
                  :readonly="readonly"
                  size="mini"
                  as-key
                  @change="$emit('change', $event)">
        <template #prefix>
            <div v-if="value" 
                 class="wh-full f-v-center _ml-0.3">
                <span class="wh-0.75 rounded-sm"
                      :style="{ backgroundColor: value }" />
            </div>
        </template>
        <template #option="{ option }">
            <div class="space-x-0.5 f-v-center">
                <span class="wh-0.75 rounded-sm"
                      :style="{ backgroundColor: option.value }" />
                <span class="fs-0.75">{{ option.label }}</span>
            </div>
        </template>
    </select-field>
</template>

<script>
import { injectCommonFieldProps } from '@/utils/ui';

import SelectField from '@/components/fields/SelectField';

export default {
  components: {
    SelectField
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    ...injectCommonFieldProps(),

    value: { type: [Object, Array, String], default: null },
    options: { type: Array, default: () => ([]) }
  }
}
</script>
