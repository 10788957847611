<template>
    <access :no-access-class="'no-access--menu'"
            :permissions="item.permissions"
            :exclusions="item.exclusions"
            :licence-abilities="item.licenceAbility">
        <!-- With children -->
        <el-popover v-if="item.children && item.children.length"
                    :index="item.key"
                    placement="right-start"
                    trigger="hover"
                    width="240"
                    popper-class="reset-el-popover-dark rounded">
            <!-- Content -->
            <template #default>
                <div v-for="child in item.children"
                     :key="child.key">
                    <access :settings="child.settings"
                            :permissions="child.permissions" 
                            :exclusions="child.exclusions" 
                            :licence-abilities="child.licenceAbility"
                            hidable>
                        <child 
                            :item="child"
                            :selection="selection"
                            @click="go(child)" />
                    </access>
                </div>
            </template>

            <!-- Title -->
            <template #reference>
                <div class="h-3 f-y-center cursor-pointer overflow-hidden">
                    <!-- Icon -->
                    <div class="f-center wh-3 _p-0.8 no-shrink border-box">
                        <icon v-if="item.iconAsCommon || item.iconAsComponent"
                              :name="item.iconAsCommon"
                              :as="item.iconAsComponent"
                              :color="colors.icon" />

                        <i v-else
                           :class="classes.icon" />
                    </div> 

                    <!-- Label -->
                    <is-label :value="item.title"
                              color="gray-300" />
                </div> 
            </template>
        </el-popover>

        <!-- Item -->
        <el-tooltip v-else
                    :content="item.title"
                    placement="right">
            <div class="h-3 f-y-center cursor-pointer hover:bg-el-dark-hover overflow-hidden"
                 @click="go(item)">
                <!-- Icon -->
                <div class="f-center wh-3 _p-0.8 no-shrink border-box">
                    <icon v-if="item.iconAsCommon || item.iconAsComponent"
                          :name="item.iconAsCommon"
                          :as="item.iconAsComponent"
                          :color="colors.icon" />

                    <i v-else
                       :class="classes.icon" />
                </div>

                <!-- Label -->
                <is-label :value="item.title"
                          color="gray-300" />
            </div>
        </el-tooltip>
    </access>
</template>

<script>
import Child from './MenuItemChild.vue'

export default {
  components: {
    Child
  },
  props: {
    item: {
      type: Object,
      default: () => null
    },
    selection: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    classes() {
      return {
        icon: {
          [this.item.icon]: true,
          'text-gray-500': !this.isSelected(),
          'text-accent': this.isSelected()
        }
      }
    },

    colors() {
      return {
        icon: [ this.isSelected() && 'accent' ].filter(x => x)[0] || 'white/25'
      }
    }
  },
  methods: {
    go(item) {
      const { to } = item

      if (to && to.name !== this.$route.name) {
        this.$router.push(to)
        this.$emit('select', item)
      }

      this.$emit('click')
    },

    isSelected() {
      return this.item.key === this.selection?.key || (this.item.children || []).find(({ key }) => this.selection?.key === key)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
