<template>
    <el-container class="task-create-page" 
                  direction="vertical">
        <page-header :page-title="pageTitle" />
        <el-main class="reset-el-main">
            <el-tabs v-model="activeName"
                     class="h-full rows-min-minmax pdown-1 border-box">
                <el-tab-pane label="Информация"
                             name="info"
                             class="h-full">
                    <!-- For (building_order) -->
                    <task-create-type-building-order 
                        v-if="typeBuildingOrder" />
                    <!-- For (defects_and_violations) -->
                    <task-create-type-defects-and-violations 
                        v-else-if="typeDefectsAndViolations"
                        :point="point" />
                    <!-- For (photo_order) -->
                    <task-create-type-photo-order 
                        v-else-if="typePhotoOrder"
                        :dir-job-type-id="dirJobTypeId" />
                    <!-- For (acceptance_of_work) -->
                    <task-create-acceptance-of-work 
                        v-else-if="typeAcceptanceOfWork" />
                </el-tab-pane>

                <!-- TODO@deprecated The functionality of adding documents when creating tasks is not currently regulated -->
                <el-tab-pane v-if="false"
                             label="Документы"
                             name="documents">
                    <el-row type="flex">
                        <el-col :span="16">
                            <task-documents v-show="activeName==='documents'"
                                            ref="taskDocuments"
                                            :mode="'selector'" />
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-main>
    </el-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import { types } from '@/models/tasks'

import PageHeader from '@/components/layout/PageHeader'
import TaskDocuments from '@/components/task/TaskDocuments'

export default {
  name: 'TaskCreate',
  components: {
    TaskCreateTypeBuildingOrder: () => import('@/components/task/TaskCreateTypeBuildingOrder'),
    TaskCreateTypeDefectsAndViolations: () => import('@/components/task/TaskCreateTypeDefectsAndViolations'),
    TaskCreateTypePhotoOrder: () => import('@/components/task/TaskCreateTypePhotoOrder'),
    TaskCreateAcceptanceOfWork: () => import('@/components/task/TaskCreateAcceptanceOfWork'),
    PageHeader,
    TaskDocuments
  },
  props: {
    type: { type: String, default: null },
    dirJobTypeId: { type: String, default: null }
  },
  data: () => ({
    activeName: 'info',
    taskType: '',
    editedItem: {},
    loading: false,
    errors: [],
    options: [],
    selectLoading: false,
    createdTaskId: null,
    pageTitle: null,

    point: null
  }),
  computed: {
    typeDefectsAndViolations() {
      return this.taskType === types.DEFECTS_AND_VIOLATIONS
    },

    typeAcceptanceOfWork() {
      return this.taskType === types.ACCEPTANCE_OF_WORK
    },

    typeBuildingOrder() {
      return this.taskType === types.BUILDING_ORDER
    },

    typePhotoOrder() {
      return this.taskType === types.PHOTO_ORDER
    },

    // NOT_REVIEWED

    ...mapGetters({
      selectedProject: 'project/selectedProject',
      taskTypesOptionsCreate: 'tasks/taskTypesOptionsCreate'
    }),
    taskTypesOptions() {
      const nonIncludedTypes = ['checking_act', 'protocol', 'acceptance_of_work'];
      return this.taskTypesOptionsCreate.filter(item => !nonIncludedTypes.some(type => type ===  item.value));
    }
  },
  watch: {
    taskType: {
      immediate: true,
      handler(val) {
        if (val === 'building_order') {
          this.pageTitle = 'Составление предписания';
        } else {
          this.pageTitle = null;
        }
      }
    }
  },
  mounted() {
    const { type, plan, x, y } = this.$route.query

    x && y && (this.point = { 
      x, 
      y,
      floor_plan_id: plan
    })

    this.taskType = this.type || type

    !this.taskTypesOptionsCreate.length && this.getTaskTypes()
  },
  methods: {
    ...mapActions({getTaskTypes: 'tasks/getTaskTypes'})
  }
};
</script>

<style lang="scss">
.task-create-page {
  position: relative;

  .el-tabs__content {
    position: static;
  }
}
</style>

