<template>
    <el-row type="flex"
            :justify="message.user.id === profile.id ? 'end' : 'start'">
        <el-col
            :class="['message-container my-2 p-2', + message.user.id === profile.id ? 'message-container--responder' : 'message-container--sender']"
            :span="18">
            <div class="space-y-0.5">
                <div class="f-x-between">
                    <!-- User -->
                    <user-thumb :user="user"
                                compact
                                with-avatar
                                with-email />

                    <!-- Edit -->
                    <div>
                        <el-button v-if="editable"
                                   icon="el-icon-edit"
                                   size="mini"
                                   circle
                                   @click="editMessage(message)" />
                    </div>
                </div>
                <div>
                    <is-label :value="text" />
                </div>
                <div class="f-x-end">
                    <is-label :value="message.created_at | dateFormat(timeZone,'DD.MM.YY')"
                              tertiary
                              compact />
                </div>
            </div>
        </el-col>
    </el-row>
</template>
<script>
import { mapGetters } from 'vuex'

import { isEditable, getUser, getText } from '@/models/task-messages'

import UserThumb from '@/components/users/UserThumb.vue'

export default {
  components: {
    UserThumb
  },
  props: {
    message: { type: Object, default: null }
  },
  computed: {
    ...mapGetters({ profile: 'auth/profile' }),

    text() {
      return getText(this.message)
    },

    editable() {
      return isEditable(this.message)
    },

    user() {
      return getUser(this.message)
    }
  },
  methods: {
    editMessage(message) {
      this.$emit('edit-message', message);
    }
  }
};
</script>
