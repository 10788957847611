import { labelAt } from '@/utils/date'
import { then } from '@/utils/immutable'
import { getComplexName, getVisionImageLabel, getVisionLabel, getZoneLabel } from '@/models/street-falcon'

const lower = x => x?.toLowerCase()

export const labels = {
  floor: 'этаж',
  point: 'точка',
  photo: 'фотография от'
}

export const getProjectName = ({ name } = {}) => name

export const formatPosition = ({ 
  project, 
  house, 
  floor,
  floorPlan,
  floor_work_plan,
  definingPoint,
  defining_point_image,

  street_falcon_complex,
  street_falcon_zone,
  street_falcon_segment,
  street_falcon_image
} = {}, { 
  separator = ', ' ,
  withoutProject = false,
  withoutHouse = false,
  withoutFloor = false,
  withoutPlan = false,
  withoutPoint = false
} = {}) => {
  withoutHouse = !!street_falcon_complex
  withoutProject = !!street_falcon_complex
  withoutFloor = !!street_falcon_complex
  withoutPoint = !!street_falcon_complex

  const result = [
    // project
    !withoutProject && project?.name,
    // house
    !withoutHouse && [
      house && !house.label && house.street,
      house && !house.label && house.number,
      house && !house.label && house.housing && `к${house.housing}`,
      house && house.label
    ].filter(x => x).join(' '),
    // floor
    !withoutFloor && then(floor, x => x.label || x.number),
    // plan
    !withoutPlan && then(floorPlan || floor_work_plan, x => x.name || `план от ${labelAt(x.created_at, { iso: true, withTime: false, withAlias: false })}`),
    // point
    !withoutPoint && then(definingPoint?.name, x => `${labels.point} ${x}`),
    // photo
    !withoutPoint && then(defining_point_image?.shot_at, x => labelAt(x, { prefix: labels.photo, iso: true, withTime: false })),
    
    // complex
    then(street_falcon_complex, getComplexName),
    // zone
    then(street_falcon_zone, compose(lower, getZoneLabel)),
    // vision
    then(street_falcon_segment, compose(lower, getVisionLabel)),
    // vision image
    then(street_falcon_image, compose(lower, getVisionImageLabel))
  ].filter(is).join(separator)

  return result
}

export const getPlanIdFromPosition = ({ floorPlan } = {}) => floorPlan?.id
export const getPointIdFromPosition = ({ definingPoint } = {}) => definingPoint?.id
export const getPhotoIdFromPosition = ({ defining_point_image } = {}) => defining_point_image?.id

export const hasTableau = ({ tableau_url }) => !!tableau_url
