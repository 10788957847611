<template>
    <div class="navigation">
        <div class="navigation__header">
            <span class="navigation__header__title text-size--body-small text-el-dark">Структура</span>
            <select-document-type :value="type" 
                                  class="navigation__header__select"
                                  with-all
                                  independent
                                  as-key
                                  @change="$emit('change-type', $event)" />
        </div>
        <navigation-tree-tab :tree-type="treeType"
                             @change-tree-type="(tab) => $emit('change-tree-type', tab)" />
        <navigation-tree :type="type"
                         :need-title="false"
                         :tree-type="treeType" />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {dateFormat, distanceToNow} from '@/utils/datetime'

import { types, treeTypes } from '@/models/documents'

import NavigationTree from '@/components/documents/NavigationTree'
import NavigationTreeTab from '@/components/documents/NavigationTreeTab'
import SelectDocumentType from '@/components/fields/SelectDocumentTypeField'

export default {
  components: {
    NavigationTree, NavigationTreeTab, SelectDocumentType
  },
  props: {
    type: { type: String, default: null },
    treeType: { type: String, default: treeTypes.TREE_BY_APPROVAL, require: false }
  },
  data() {
    return {
      loading: false,
      activeNode: null,
      dragFolder: null,
      defaultProps: {
        children: 'children',
        label: 'name'
      },

      types
    }
  },
  computed: {
    ...mapGetters({
      getBimInfo: 'bim360/getBimStatus'
    })
  },
  methods: {
    ...mapActions('bim360', ['getAutodeskInfo']),

    dateDistanceToNow (date) {
      const formatDate = dateFormat(date)
      return distanceToNow(formatDate)
    },
    loadAutodeskInfo () {
      this.loading = true
      this.getAutodeskInfo({
        project: this.$route.params.projectId
      }).then()
        .catch(() => {
          if (this.interval) clearInterval(this.interval)

          this.$message({
            type: 'error',
            message: 'Не удалось получить данные'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss">
.navigation {
  &__header {
    .el-select {
      .el-input {
        &__inner {
          height: 24px;
        }

        &__suffix {
          .el-input__icon {
            line-height: 24px;
            color: #409EFF;
          }
        }
      }
    }

    &__select {
      .el-input {
        &__inner {
          padding: 2px 12px;
          font-size: 12px;
          font-weight: 500;
          color: #409EFF;
        }
      }
    }
  }
  
  .el-radio-group {
    display: flex;
  }

  &__tabs {
    margin: 8px 16px;
    box-sizing: border-box;
    
    &__item {
      width: 100%;

      .el-radio-button__inner {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.navigation {
  position: fixed;
  width: 300px;

  max-height: calc(100% - 108px);
  overflow-y: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid #E4E7ED;

    &__select {
      width: 177px;
    }
  }

  &__sync-info {
    background: #fff;
    position: fixed;
    bottom: 0;
    padding: 10px;

    font-size: 12px;
    color: #606266;

  }
}
.folder-title {
  padding: 10px 17px;
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;

  font-size: 12px;
  color: #606266;
}

.el-tree {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 9px;

    width: 100%;
    max-width: calc(100% - 98px);
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 9px;

    font-size: 12px;
    color: #606266;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ::v-deep {
      .el-skeleton__paragraph {
        margin-top: 0;
      }
    }
  }

  &__btms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    opacity: 0;
    transition: all ease .2s;

    font-size: 14px;
    margin-right: 16px;

    i {
      &:hover {
        opacity: .5;
      }
    }
  }

  ::v-deep {
    .el-tree-node {

      &__expand-icon {
        margin: 0 10px;
      }
      &__content {
        height: 32px;

        &:hover {
          .el-tree__btms {
            opacity: 1;
          }
        }
      }

    }
  }
}

</style>
