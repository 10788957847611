<template>
    <div class="abs w-full t-1 l-1 depth-4">
        <div class="f">
            <!-- Go back -->
            <plan-button tooltip="Назад"
                         icon="back"
                         @click="goBack" />

            <div class="w-1" />
          
            <!-- User markup -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         permissions="project_images_marking"
                         tooltip="Добавить разметку"
                         icon="mark"
                         :active="editing === VIEW_MODE_STATES.EDIT"
                         @click="toggleEditingByState(VIEW_MODE_STATES.EDIT)" /> 

            <!-- Defect -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         permissions="project_tasks_defects_create"
                         tooltip="Отметить дефект"
                         icon="warning"
                         :active="editing === VIEW_MODE_STATES.DEFECT"
                         @click="toggleEditingByState(VIEW_MODE_STATES.DEFECT)" />

            <!-- Toggle markup -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA && state !== VIEW_MODE_TYPES.LABELBOX"
                         permissions="project_images_marking"
                         tooltip="Показать пользовательскую разметку"
                         icon="grid"
                         @click="toggleMarkup" />

            <!-- Save -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         v-show="!isSaved"
                         :active="true"
                         label="Сохранить изменения"
                         @click="updateMeta" />

            <div class="w-1" />

            <!-- To labelbox -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.LABELBOX"
                         tooltip="2D вид"
                         icon="image"
                         @click="setState(VIEW_MODE_TYPES.LABELBOX)" />

            <!-- To panorama -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.PANORAMA"
                         tooltip="3D вид"
                         icon="cube"
                         @click="setState(VIEW_MODE_TYPES.PANORAMA)" />

            <!-- Recognize -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         permissions="project_images_marking"
                         tooltip="Распознать картинку"
                         icon="visible"
                         @click="detect" />

            <!-- Edit -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         permissions="project_images_edit"
                         tooltip="Редактировать картинку"
                         icon="edit"
                         @click="edit" />

            <!-- Delete -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         permissions="project_images_edit"
                         tooltip="Удалить выбранную мету"
                         icon="trash"
                         @click="deleteSelectedMark" />

            <div class="w-1" />

            <!-- Share -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         permissions="project_images_edit"
                         tooltip="Поделиться"
                         icon="share"
                         @click="share" />

            <div class="w-1" />

            <!-- Transition point -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         :active="editing === VIEW_MODE_STATES.POINT"
                         permissions="project_images_3d_tour_marking"
                         tooltip="Добавить точку перехода"
                         icon="location"
                         @click="toggleEditingByState(VIEW_MODE_STATES.POINT)" />

            <!-- Annotation -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         :active="editing === VIEW_MODE_STATES.ANNOTATION"
                         permissions="project_images_3d_tour_marking"
                         tooltip="Добавить комментарий 3D тура"
                         icon="message"
                         @click="toggleEditingByState(VIEW_MODE_STATES.ANNOTATION)" />

            <!-- Camera anchor -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         :active="editing === VIEW_MODE_STATES.CAMERA_ANCHOR"
                         permissions="project_images_3d_tour_marking"
                         tooltip="Добавить точку направления камеры"
                         icon="camera"
                         @click="toggleEditingByState(VIEW_MODE_STATES.CAMERA_ANCHOR)" />

            <div class="w-1" />

            <!-- Comment -->
            <plan-button :active="editing === VIEW_MODE_STATES.COMMENT"
                         permissions="project_comments_edit"
                         tooltip="Оставить комментарий"
                         icon="message"
                         @click="toggleEditingByState(VIEW_MODE_STATES.COMMENT)" />

            <div class="w-1" />

            <!-- Gpr -->
            <plan-button v-if="pointGprJobs.length"
                         :active="showGpr"
                         permissions="project_gpr_show"
                         tooltip="Показать данные ГПР"
                         icon="job"
                         @click="toggleGpr" />

            <!-- Timeline -->
            <plan-button v-if="pointPhotosForTimeline.length"
                         :active="showTimeline"
                         tooltip="Timeline (beta)"
                         icon="clock"
                         @click="toggleTimeline" />

            <!-- XUIM -->
            <bim-button :show-mark="isShowMarks(MARK_TYPES.FORGE)"
                        @open="showBimViewer" />

            <el-tooltip v-if="false"
                        content="Показать разметку BIM"
                        placement="bottom">
                <el-button :class="[isShowMarks(MARK_TYPES.BIM) ? 'is-active' : '']"
                           type="info"
                           @click="toggleShowMark(MARK_TYPES.BIM); toggleEditingByState(VIEW_MODE_STATES.VIEW)">
                    BIM
                </el-button>
            </el-tooltip>
            <access v-if="false && isShowMarks(MARK_TYPES.BIM)"
                    :permissions="permissionList['photo.bim.markup']">
                <el-tooltip content="Добавить точку BIM"
                            placement="bottom">
                    <el-button :class="[editing === VIEW_MODE_STATES.BIM ? 'is-active' : '']"
                               icon="el-icon-aim"
                               type="info"
                               @click="toggleEditingByState(VIEW_MODE_STATES.BIM)" />
                </el-tooltip>
            </access>
            <el-tooltip v-if="isShowMarks(MARK_TYPES.BIM) && floor && floor.bim_doc_url"
                        content="Посмотреть в BIM 360 Docs"
                        placement="bottom">
                <el-button class="big-icon"
                           type="info">
                    <el-link :href="floor.bim_doc_url"
                             target="_blank"
                             :underline="false">
                        <bim-icon :height="28" />
                    </el-link>
                </el-button>
            </el-tooltip>

            <div class="w-1" />

            <!-- Toggle split mode -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         :active="splitMode"
                         tooltip="Режим раздельного просмотра"
                         icon="sidebar"
                         @click="toggleSplitMode" />

            <!-- Toggle split sync -->
            <plan-button v-if="state === VIEW_MODE_TYPES.PANORAMA && splitMode"
                         :active="syncSplitMode"
                         tooltip="Режим синхронного просмотра"
                         icon="compare"
                         @click="toggleSplitSync" />

            <div class="w-1" />

            <!-- What is it -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         :active="syncSplitMode"
                         permissions="project_structure_edit"
                         tooltip="Список планируемых объектов"
                         icon="code"
                         @click="optionsPlanPoints" />

            <div class="w-1" />

            <!-- Minimap toggle -->
            <plan-button v-if="state !== VIEW_MODE_TYPES.DULA"
                         v-show="plan"
                         :active="minimap"
                         tooltip="Миникарта"
                         icon="plan"
                         @click="toggleMinimap" />

            <!-- Minimap -->
            <div v-if="state !== VIEW_MODE_TYPES.DULA && plan"
                 v-show="minimap"
                 :class="classes.minimap"
                 :style="styles.minimap">
                <floor-map ref="map"
                           :plan-id="planId"
                           :plan-type="planType"
                           :filter="filter"
                           :controls="true"
                           :floor-image="planImage"
                           :points="points"
                           :tech-rooms="techRooms"
                           :show-bar-helpers="true"
                           :ui-hide="true"
                           :minimap-size="minimapSize"
                           :should-display-sight="state === VIEW_MODE_TYPES.PANORAMA"
                           @point:select="goToPoint"
                           @resize-as-minimap="resizeAsMinimap" />
            </div>
        </div>

        <div class="spacer" />
    </div>
</template>

<script>
import { VIEW_MODE_TYPES, STATES as VIEW_MODE_STATES } from '@/utils/viewer/view-mode'
import { MARK_TYPES } from '@/utils/viewer/marks'
import { definePlanFilter } from '@/values/filters'

import FloorMap from '@/components/map/FloorMap'
import PlanButton from '@/components/map/PlanButton'
import BimIcon from '@/components/icons/BimIcon'
import BimButton from '@/components/pilotCloud/Button'

export default {
  components: {
    PlanButton,
    FloorMap,
    BimIcon,
    BimButton
  },
  props: {
    loading: { type: Boolean, default: false },
    wrap: { type: Boolean, default: false },
    state: { type: String, default: null },
    isSaved: { type: Boolean, default: true },
    editing: { type: Number, default: 0 },
    splitMode: { type: Boolean, default: false },
    syncSplitMode: { type: Boolean, default: false },
    pointGprJobs: { type: Array, default: () => [] },
    pointPhotosForTimeline: { type: Array, default: () => [] },
    points: { type: Array, default: () => [] },
    techRooms: { type: Array, default: () => [] },
    showMarks: { type: Array, default: () => [] },
    plan: { type: Object, default: null },
    planImage: { type: Object, default: null },
    showGpr: { type: Boolean, default: false },
    showTimeline: { type: Boolean, default: false }
  },
  data() {
    return {
      minimap: false,
      minimapSize: +get('minimap-size', 1, { cookie: true }),

      filter: definePlanFilter({
        uiHide: true,
        planType: this.plan?.type
      }),

      VIEW_MODE_TYPES,
      VIEW_MODE_STATES,
      MARK_TYPES
    }
  },
  computed: {
    classes() {
      return {
        minimap: {
          'abs t-3 r-2': true
        }
      }
    },

    styles() {
      return {
        minimap: `width: ${19 * this.minimapSize}rem; height: ${14 * this.minimapSize}rem`
      }
    },

    planId() {
      return this.plan?.id
    },

    planType() {
      return this.plan?.type
    }
  },
  methods: {
    goBack() {
      this.$emit('go-back')
    },

    setState(state) {
      this.$emit('set-state', state)
    },

    toggleEditingByState(state) {
      this.$emit('toggle-editing-by-state', state)
    },

    toggleMinimap() {
      this.minimap = !this.minimap
      this.$nextTick(() => this.$refs.map && this.$refs.map.redraw())
    },

    toggleSplitMode() {
      this.$emit('toggle-split-mode')
    },

    toggleSplitSync() {
      this.$emit('toggle-split-sync')
    },

    toggleShowMark(mark) {
      this.$emit('toggle-show-mark', mark)
    },

    toggleGpr() {
      this.$emit('toggle-gpr')
    },

    toggleTimeline() {
      this.$emit('toggle-timeline')
    },

    detect() {
      this.$emit('detect')
    },

    edit() {
      this.$emit('edit')
    },

    updateMeta() {
      this.$emit('update-meta')
    },

    share() {
      this.$emit('share')
    },

    isShowMarks(markType) {
      return this.showMarks.indexOf(markType) !== -1;
    },

    goToPoint(point) {
      this.$emit('go-to-point', point)
    },

    optionsPlanPoints() {
      this.$emit('options-plan-points')
    },

    toggleMarkup() {
      this.$emit('toggle-markup')
    },

    deleteSelectedMark() {
      this.$emit('delete-selected-mark')
    },

    showBimViewer() {
      this.$emit('show-bim-viewer')
    },

    resizeAsMinimap() {
      const k = 0.75
      const x = [+get('minimap-size', 1, { cookie: true })].map(x => x >= (1 + k * 2) ? 1 : x + k)[0]

      set('minimap-size', this.minimapSize = x, { cookie: true })
    }
  }
}
</script>
