export const createPlanSnapshot = ({ planId, planType, layer, polygonsForPatch, polygonsForRestore, polygonsForCreate, polygonsForAttach }) => {
  return {
    planId,
    planType,

    layerId: layer?.id,
    layerType: layer?.type,

    polygonsForPatch,
    polygonsForRestore,

    polygonsForCreate,
    polygonsForAttach,

    count: [
      polygonsForCreate,
      polygonsForAttach
    ]
      .filter(is)
      .map(x => x.length)
      .sum()
  }
}
