<template>
    <el-container direction="vertical">
        <div class="documents">
            <div class="documents__navigation">
                <navigation :type="type"
                            :tree-type="treeType"
                            @change-type="handleChangeType"
                            @change-tree-type="handleChangeTreeType" />
            </div>
            <div class="documents__content">
                <document-header :view="cardViewFull"
                                 :type="type"
                                 :tree-type="treeType"
                                 @switchView="switchView"
                                 @change="init" />
                
                <template v-if="documents.length || documentsLoading">
                    <div ref="scrollContent"
                         v-loading:fullscreen="documentsLoading">
                        <document-cards v-if="cardViewFull"
                                        :documents="documents"
                                        @document-click="previewDocument"
                                        @document-move="move" />
                        <document-table v-else
                                        :sort="sort"
                                        :documents="documents"
                                        @document_sort="handleSortDocuments"
                                        @document-click="previewDocument"
                                        @document-move="move" />
                    </div>
                </template>
                <el-empty v-else
                          description="Нет документов" />
                <el-pagination v-if="documents.length"
                               class="documents__content__pagination"
                               background
                               :current-page.sync="documentsPagination.page"
                               :page-size.sync="documentsPagination.size"
                               layout="prev, pager, next"
                               :total="documentsPagination.total"
                               @current-change="handleCurrentChange" />
                    

                <!-- Comparison -->
                <comparison />

                <!-- Viewer -->
                <viewer v-if="document && documentViewerOpened"
                        :document="document"
                        @close="closeDocumentViewer" />
            </div>
        </div>
    </el-container>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import { actionable, pagenable } from '@/store/connectors'

import { hasExtensionOf, isBimCompleted, isBimNotSupported, treeGroupTypes, treeTypes } from '@/models/documents'

import DocumentHeader from '@/components/documents/DocumentHeader'
import DocumentCards from '@/components/documents/DocumentCards'
import DocumentTable from '@/components/documents/DocumentTable'
import Comparison from '@/components/documents/Comparison'
import Viewer from '@/components/documents/Viewer'
import Navigation from '@/components/documents/Navigation'
import { formatSortParam } from '@/utils/format'

export default {
  name: 'Documents',
  components: {
    DocumentHeader,
    DocumentCards,
    DocumentTable,
    Comparison,
    Navigation,
    Viewer
  },
  mixins: [
    actionable({ on: 'project', name: 'changeDocument' }),
    pagenable({ on: 'documents', name: 'documentsForTree' })
  ],
  beforeRouteUpdate(to, from, next) {
    this.init(to)
    next()
  },
  beforeRouteLeave(_to, _from, next) {
    this.closeDocumentViewer()
    next()
  },
  data() {
    return {
      loading: false,
      filter: {},
      cardViewFull: false,
      currentPage: 1,
      sort: { prop: 'created_at', order: 'descending' },
      orderType: { descending: '-', ascending: '' },
      comparisonOpened: false,
      treeType: treeTypes.TREE_BY_APPROVAL
    }
  },
  computed: {
    ...mapGetters('interactions', ['documentViewerOpened']),
    ...mapGetters('project', ['project', 'preparedDocumentIndex', 'preparedDocumentPagination', 'projectDocumentLoading']),
    ...mapGetters('documents', {
      document: 'selectedDocument'
    }),
    ...mapGetters('account', ['hasAccess']),

    type() {
      return this.$route.query?.type
    },

    isTreeByApproval() {
      return this.treeType === treeTypes.TREE_BY_APPROVAL
    },

    documents() {
      return this.documentsForTree
    },

    documentsLoading() {
      return this.documentsForTreeLoading
    },

    documentsPagination() {
      const pagination = this.documentsForTreePagination
      
      return {
        page: pagination.page,
        size: pagination.size,
        total: pagination.total
      }
    }
  },
  mounted() {
    this.init()

    this.hasAccess({ permissions: ['project_documents_bim_model_show'] }) 
      && this.$socket.echoClient.private(`v2.projects.${this.project.id}.project_documents.bim_models`)
        .listen('.processing_status_updated', ({ 
          subject: { 
            project_document_id,
            bim_file,
            new_processing_status,
            translated_new_processing_status
          } = {} 
        }) => 
          project_document_id && this.changeDocument({
            id: project_document_id,
            bim_file,
            processing_status: new_processing_status,
            translated_processing_status: translated_new_processing_status
          }))
  },
  beforeDestroy() {
    this.$socket.echoClient.leave(`v2.projects.${this.project.id}.project_documents.bim_models`);
  },
  methods: {
    ...mapActions('interactions', ['closeDocumentViewer']),
    ...mapActions('project', ['getProjectDocumentIndex', 'downloadProjectDocument']),
    ...mapMutations({
      showForm: 'form/SHOW_FORM',
      showDialog: 'dialog/SHOW_DIALOG'
    }),
    ...mapActions('documents', ['setCurrentFolder']),
    ...mapActions({
      setSelectedBim: 'pilotCloudViewer/SET_SELECTED_BIM'
    }),
   
    handleChangeType(type) {
      this.$router.push({
        name: 'project.documents',
        query: {
          ...type && { type }
        }
      })
    },
    
    handleChangeTreeType(type) {
      this.treeType = type

      this.$router.push({
        name: 'project.documents',
        query: {
          ...this.type && { type: this.type },
          ...this.isTreeByApproval && { group: treeGroupTypes.APPROVED },
          page: 1
        }
      })
    },

    handleSortDocuments({ prop, order }) {
      this.sort = { prop, order }
      this.init()
    },

    previewDocument(x) {
      hasExtensionOf(x, 'pdf') && this.previewDocumentAsPdf(x)
      x.bim_model && this.previewDocumentAsBim(x)
    },

    previewDocumentAsPdf(x) {
      this.loading = true

      this.downloadProjectDocument({
        projectId: this.$route.params.projectId,
        projectDocumentId: x.id
      }).then(r => {
        const file = new Blob(
          [r],
          {type: 'application/pdf'})
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      }).finally(() => this.loading = false)
    },

    previewDocumentAsBim(x) {
      if (isBimNotSupported(x)) {
        return dialogs.error.call(this, { message: 'Данный тип документа больше не поддерживается' })
      }

      if (!isBimCompleted(x)) {
        return dialogs.error.call(this, { message: 'Документ находится в процессe обработки' })
      }

      this.setSelectedBim(x)
      this.showDialog({
        dialogName: 'pilot-cloud-viewer',
        dialogTitle: '',
        action: () => {},
        callback: () => {}
      })
    },

    move(x) {
      this.showForm({
        formName: 'document-move-to-folder',
        formTitle: 'Переместить в папку',
        callback: this.init.bind(this, undefined),
        payload: x
      })
    },

    // NOT REVIEWED

    switchView() {
      this.cardViewFull = !this.cardViewFull
    },
    handleCurrentChange(page) {
      this.currentPage = page

      this.$router.push({
        name: 'project.documents',
        query: {
          ...this.$route.query,
          page
        }
      })

      this.$refs.scrollContent.children[0].scrollTo(0, 0)
    },
    init(route = this.$route) {
      const { type, from, to, folder, group, page } = route.query || {}

      this.fetchDocumentsForTree({
        type,
        group: this.isTreeByApproval ? group || treeGroupTypes.APPROVED : null,
        folderId: folder,
        page,
        from,
        to,
        sort: !this.cardViewFull && this.sort.order && formatSortParam(this.sort.prop, this.sort.order),

        withAuthor: true,
        withAvatar: true, 
        withOrganization: true, 
        withApprovalTask: true, 
        withMainDocument: true,
        withAccompanyingDocuments: true,
        withBim: true,

        withStructurables: true,
        withLastThreeDownloadedUsers: true 
      })
        .catch((e) => this.$notify({
          type: e === 'cancel' ? 'warning' : 'error',
          title: e === 'cancel' ? 'Предыдущий запрос был отменен новым' : 'При загрузке документов произошла ошибка'
        }))
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-main {
  padding-top: 40px;
  &_full {
    padding: 0;
  }
}



::v-deep .el-select-dropdown {
  max-width: 50%;
}

.documents {
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: 100%;

  &__navigation {
    width: 300px;
  }
  &__content {
    border-left: 1px solid #E4E7ED;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .documents-list {
      &:not(&_full) {
        flex: 1 1 auto;
        height: calc(100vh - 158px);
        overflow: auto;
        margin-bottom: 0;
      }

      &_full {
        padding: 0 10px;
        max-height: calc(100vh - 158px);
        overflow: auto;
      }
    }

    &__pagination {
      flex: 0 0 auto;
      padding: 10px 5px;
    }
  }
}
</style>
