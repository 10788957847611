<template>
    <div ref="draggable"
         :class="classes.graggable">
        <div :class="classes.header"
             @mousedown.prevent="draggableStart">
            <slot name="header" />
        </div>
        <slot />
    </div>
</template>

<script>
export default {
  data() {
    return {
      draggable: false,

      deltaX: null,
      deltaY: null
    }
  },

  computed: {
    classes() {
      return {
        graggable: {
          'abs depth-100 f-col r-0 b-0': true,
          'cursor-drag': this.draggable
        },
        header: {
          'cursor-grab': !this.draggable
        }
      }
    }
  },

  methods: {
    draggableStart(event) {
      const { x, y } = event
      const { left, top } = this.$refs.draggable.getBoundingClientRect()
      
      this.deltaX = x - left
      this.deltaY = y - top

      this.draggable = true

      document.addEventListener('mousemove', this.move)
      document.addEventListener('mouseup', this.draggableEnd)
    },
    
    draggableEnd() {
      this.draggable = false

      document.removeEventListener('mousemove', this.move)
      document.removeEventListener('mouseup', this.draggableEnd)
    },

    move(event) {
      const { x, y } = event
      const { width, height } = this.$refs.draggable.getBoundingClientRect()

      const left = x - this.deltaX
      const top = y - this.deltaY
      const clientWidth = document.documentElement.clientWidth
      const clientHeight = document.documentElement.clientHeight

      const newLeft = left + width >= clientWidth 
        ? clientWidth - width 
        : left <= 0 ? 0 : left

      const newTop = top + height > clientHeight 
        ? clientHeight - height 
        : top <= 0 ? 0 : top

      this.$refs.draggable.style.left = `${newLeft}px`
      this.$refs.draggable.style.top = `${newTop}px`
    }
  }
}
</script>