/**
 * Format IP value from number representation
 * @param {number} ip
 * @returns {string}
 */
export const formatIP = ip => {
  const d = ip & 255
  const c = ((ip >> 8) & 255)
  const b = ((ip >> 16) & 255)
  const a = ((ip >> 24) & 255)
  return `${a}.${b}.${c}.${d}`
}

/**
 * Select a word declension by number position
 * @param {string[]} words - Options of word declension
 * @returns {string}
 */
export const wordOfNumber = (number, words) => 
  words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]]

export const toSnakeCase = value => value.replace(/[A-Z]/g, each => `_${each}`)

export const toCapitalCase = value => value[0].toUpperCase() + value.slice(1)

export const trim = (text, limit = 128) => text.length > limit ? text.slice(0, limit) + '...' : text

export const formatSortParam = (prop, order) => {
  switch (order) {
  case 'ascending':
    return `${prop}`
  case 'descending':
    return `-${prop}`
  default:
    return null;
  }
}
