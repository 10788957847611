export const TASK_STATUSES = {
  NEW: 'new',
  IN_WORK: 'in_work',
  WAIT_INFORMATION: 'wait_information',
  COMPLETED: 'completed',
  FINISHED: 'finished',
  FINISHED_NOT_ACCEPTED: 'finished_not_accepted',
  REFUSED: 'refused'
}

export const TASK_TYPES = {
  DEFECTS_AND_VIOLATIONS: 'defects_and_violations',
  BUILDING_ORDER: 'building_order',
  PHOTO_ORDER: 'photo_order',
  PROTOCOL: 'protocol',
  CHECKING_ACT: 'checking_act',
  ACCEPTANCE_OF_WORK: 'acceptance_of_work',
  PLAIN: 'plain',
  APPROVAL_PROCESS: 'approval_process'
}

export const TASK_ACTIONS = {
  TAKE_TO_WORK: 'take_to_work',
  TAKE_TO_WORK_SECOND_STAGE: 'take_to_work_second_stage',

  REFUSE: 'refuse',
  FINISH: 'finish',
  FINISH_NOT_ACCEPT: 'finish_not_accept',
  CANCEL: 'cancel',
  COMPLETE: 'complete',
  COMPLETE_REWORK: 'complete_rework',

  FINISH_SECOND_STAGE: 'finish_second_stage',
  FINISH_NOT_ACCEPT_SECOND_STAGE: 'finish_not_accept_second_stage',

  CHECK_PROJECT_WORK_PRODUCTION: 'check_project_work_production',
  NON_CHECK_PROJECT_WORK_PRODUCTION: 'non_check_project_work_production',

  GIVE_TO_SECOND_WORKER: 'give_to_second_worker',

  SEND_TO_MAIL: 'send_to_mail',
  SEND_FOR_REVISION: 'send_for_revision',
  SEND_TO_REVIEW: 'send_to_review'
}

export const DEFECT_KINDS = {
  REMOVABLE: 'removable',
  SIGNIFICANT: 'significant',
  CRITICAL: 'critical'
}

export const TASK_MEMBER_SUB_ROLES = {
  WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_FIRST_STAGE: 70,
  WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_SECOND_STAGE: 71,

  DEFECT_CONTRACTOR: 85,
  DEFECT_ENGINEER: 86,
  DEFECT_ENGINEER_SECOND_STAGE: 87,
  DEFECT_CONTRACTOR_SECOND_STAGE: 88,

  INITIATOR_APPROVAL_PROCESS_TASK_CREATOR: 35,
  INITIATOR_APPROVAL_PROCESS_TASK_CHIEF_PROJECT_ENGINEER: 36,
  WORKER_APPROVAL_PROCESS_TASK_ACTUAL_ITERATION: 100,
  WORKER_APPROVAL_PROCESS_TASK_NOT_ACTUAL_ITERATION: 101
}

export const DEFECT_CLASSIFIER_URL = `${process.env.VUE_APP_HOSTNAME}/repository/klassifikator_osnovnykh_vidov_defektov_v_stroitelstve_i_promyshlennosti.pdf`
