<template>
    <el-tooltip v-if="has">
        <!-- Tooltip -->
        <template #content>
            <p v-for="x in itemsForTooltip"
               :key="x.id"
               class="_m-0">
                {{ x.label }}
            </p>
        </template>

        <!-- Trigger -->
        <template #default>
            <div class="f space-x-0.25">
                <el-tag v-for="x in items"
                        :key="x.id"
                        :type="x.mood"
                        :effect="dark ? 'dark' : 'light'"
                        class="fs-0.7"
                        :size="size">
                    {{ x.value }}
                </el-tag>
            </div>
        </template>
    </el-tooltip>
</template>

<script>
import { wordOfNumber } from '@/utils/format'

const foo = ({ value, labels }) => (k, { mood }, d) => ({ id: k, value: value[k] ?? d, label: labels[k], mood })

export default {
  props: {
    value: { type: Object, default: () => ({}) },

    withFinishedAccepted: { type: Boolean, default: false },
    withFinishedNotAccepted: { type: Boolean, default: false },
    withNotProcessed: { type: Boolean, default: false },
    withAll: { type: Boolean, default: false },
    withAllTooltip: { type: Boolean, default: false },

    dark: { type: Boolean, default: false },
    size: { type: String, default: null }
  },
  computed: {
    labels() {
      const [x, y, z] = [
        this.value.finished_accepted, 
        this.value.finished_not_accepted, 
        this.value.not_processed
      ].map(x => x ?? 0)

      const q = [x, y, z].sum()

      return {
        finished_accepted: `${x} ${wordOfNumber(x, ['работа принята', 'работы приняты', 'работ принято'])}`,
        finished_not_accepted: `${y} ${wordOfNumber(y, ['работа не принята', 'работы не приняты', 'работ не принято'])}`,
        not_processed: `${z} ${wordOfNumber(z, ['объект не обработан', 'объекта не обработано', 'объектов не обработано'])}`,
        all: `${q} ${wordOfNumber(q, ['объект размечен', 'объекта размечено', 'объектов размечено'])}`
      }
    },

    items() {
      const bar = foo({ value: this.value, labels: this.labels })

      return [
        this.withFinishedAccepted && bar('finished_accepted', { mood: 'success' }),
        this.withFinishedNotAccepted && bar('finished_not_accepted', { mood: 'danger' }),
        this.withNotProcessed && bar('not_processed', { mood: 'info' }),

        this.withAll && bar('all', { mood: 'info' }, [
          this.value.finished_accepted, 
          this.value.finished_not_accepted, 
          this.value.not_processed
        ].filter(is).sum())
      ].filter(is)
    },

    itemsForTooltip() {
      const bar = foo({ value: this.value, labels: this.labels })

      return [
        ...this.items,
        this.withAllTooltip && !this.withAll && bar('all', { mood: 'info' })
      ].filter(is)
    },

    has() {
      return Object.values(this.value).some(x => x !== undefined)
    }
  }
}
</script>
