<template>
    <el-dropdown
        trigger="click"
        :hide-on-click="false"
        @visible-change="applyVisibility"
        @command="handleClickActions">
        <el-button type=""
                   class="button"
                   :icon="loadingTableOfContent ? 'el-icon-loading' : 'el-icon-more'" />
        <el-dropdown-menu
            slot="dropdown"
            v-loading="loading"
            @mouseleave.native="unfocus">
            <!-- Repeat -->
            <el-dropdown-item v-if="isBimToReprocessing(documentItem)"
                              command="sendToReprocess">
                Повторить обработку
            </el-dropdown-item>

            <!-- Download -->
            <el-dropdown-item command="handleDownload">
                Скачать
            </el-dropdown-item>

            <!-- Edit -->
            <el-dropdown-item v-if="!approvable"
                              command="handleUpdate">
                Редактировать
            </el-dropdown-item>

            <!-- New version -->
            <el-dropdown-item v-if="!documentItem.bim_360_docs && !approvable"
                              command="handleCreateNewVersion">
                Новая версия
            </el-dropdown-item>

            <!-- Compare versions -->
            <access settings="ml_processing_documents_enable"
                    hidable>
                <el-dropdown-item v-if="comparable && !documentItem.bim_360_docs"
                                  command="handleComparison">
                    Сравнить версии
                </el-dropdown-item>
            </access>

            <!-- Move -->
            <el-dropdown-item v-if="!documentItem.bim_360_docs"
                              command="move">
                Переместить в папку
            </el-dropdown-item>

            <!-- Open if PDF (beta) -->
            <el-dropdown-item v-if="isPdfFile"
                              command="doView">
                Посмотреть<sup>β</sup>
            </el-dropdown-item>

            <el-popover
                v-if="isShowCascader"
                placement="right"
                width="340"
                popper-class="cascader-popover"
                trigger="click"
                @show="used = true"
                @hide="used = false">
                <el-cascader-panel ref="cascaderPanel"
                                   class="cascader-panel"
                                   :border="false"
                                   :liading="true"
                                   style="width: 100%;"
                                   :options="btnOptions">
                    <template slot-scope="{ node, data }">
                        <div style="width: 100%;"
                             @click="handleChangeObjects(data, node)">
                            <i v-if="data.icon"
                               :class="[data.icon]"
                               class="mr-2" />
                            <div :title="data.label.length >= 40 ? data.label : null"
                                 style="white-space: normal; line-height: 1.3; padding: 6px 0;"
                                 v-html="data.label" />
                        </div>
                    </template>
                </el-cascader-panel>
                <el-dropdown-item v-if="isPdfFile"
                                  slot="reference"
                                  class="document-list">
                    <div class="f-x-between f-y-center"
                         @click="getTableOfContents">
                        <span>Содержимое</span>
                        <i :class="[loadingTableOfContent ? 'el-icon-loading' : 'el-icon-arrow-right']"
                           class="arrow-list  mr-0" />
                    </div>
                </el-dropdown-item>
            </el-popover>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import downloadFile from 'js-file-download'

import { isComparable, isApprovable, isBimToReprocessing } from '@/models/documents'
import { actionable } from '@/store/connectors';

export default {
  mixins: [
    actionable({ on: 'documents', name: 'reprocess', loadable: true })
  ],
  props: {
    documentItem: { type: Object, default: null }
  },
  data () {
    return {
      used: false,

      isShowCascader: false,
      loadingTableOfContent: false,
      btnOptions: [{
        value: '',
        disabled: true,
        label: 'Загрузка...'
      }],

      isBimToReprocessing
    }
  },
  computed: {
    loading() {
      return this.reprocessLoading
    },

    comparable() {
      return isComparable(this.documentItem)
    },

    approvable() {
      return isApprovable(this.documentItem)
    },

    isPdfFile() {
      return this.fileExtension(this.documentItem) === 'pdf'
    }
  },
  methods: {
    ...mapMutations({
      showForm: 'form/SHOW_FORM'
    }),
    ...mapActions('project', ['downloadProjectDocument', 'getProjectDocumentIndex', 'updateProjectDocument', 'upgradeProjectDocument']),
    ...mapActions('documents', ['selectDocument']),
    ...mapActions('interactions', ['openDocumentComparison', 'openDocumentViewer']),

    applyVisibility(is) {
      this.$emit('visible', this.isShowCascader = is)
    },

    unfocus() {
      !this.used && this.$emit('visible', false)
    },

    sendToReprocess() {
      this.reprocess({ document: this.documentItem })
    },

    move() {
      this.$emit('move', this.documentItem)
    },

    // NOT REVIEWED

    getTableOfContents () {
      this.loadingTableOfContent = true

      this.$api.documents.getTableOfContents({
        project: this.$route.params.projectId,
        documentId: this.documentItem.id
      }).then(({data}) => {
        let tableOfContentsData = data?.data.map(item => {
          item.page_num = +item.page_num + 1
          return {
            page_num: item.page_num,
            value: item.id,
            label: 'Лист ' + item.page_num + ' / ' + item.section +  ' <br>— ' + item.scheme
          }
        })

        this.btnOptions = data.data.length ? tableOfContentsData : [{
          value: '',
          disabled: true,
          label: 'Данных нет'
        }]
      }).catch (e => {
        this.$message({
          type: 'error',
          message: 'При получении произошла ошибка'
        })
      }).finally (() => {
        this.loadingTableOfContent = false
      })

    },
    doView() {
      if(this.fileExtension(this.documentItem) === 'pdf') {
        this.selectDocument(this.documentItem)
        this.openDocumentViewer()
      }
    },
    async handleChangeObjects ({page_num}, node) {
      this.$emit('changeLoading', true)

      if(this.fileExtension(this.documentItem) === 'pdf') {
        let data = await this.downloadProjectDocument({
          projectId: this.$route.params.projectId,
          projectDocumentId: this.documentItem.id
        })

        const file = new Blob(
          [data],
          {type: 'application/pdf'})
        const fileURL = URL.createObjectURL(file)

        this.$emit('changeLoading', false)
        window.open(fileURL + '#page=' + page_num)
      }
    },
    handleClickActions (command) {
      if(command) this[command]()
    },
    handleUpdate() {
      this.showForm({
        formName: 'document-form',
        formTitle: 'Редактировать документ',
        action: this.updateProjectDocument,
        callback: this.getProjectDocumentIndex,
        payload: { ...this.documentItem, editType: 'update' }
      })
    },
    handleCreateNewVersion() {
      this.showForm({
        formName: 'document-form',
        formTitle: 'Добавление новой версии документа',
        action: this.upgradeProjectDocument,
        callback: this.getProjectDocumentIndex,
        payload: { ...this.documentItem, editType: 'upgrade', projectDocumentId: this.documentItem.parent ? this.documentItem.parent.id : this.documentItem.id }
      })
    },
    handleDownload(document = this.documentItem) {
      document.status === 'active' ? this.downloadDocument(document) : this.handleDownloadArchiveDocument(document)
    },
    fileExtension (file) {
      let split = file?.original_file_name?.split('.') || []
      return file?.bim_model ? 'bim' : split[split.length - 1]
    },
    async downloadDocument(projectDocument) {
      try {
        this.$emit('changeLoading', true)
        let data = await this.downloadProjectDocument({
          projectId: this.$route.params.projectId,
          projectDocumentId: projectDocument.id
        })

        downloadFile(data, projectDocument.original_file_name)
      } catch (e) {
        console.log(e)
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.$emit('changeLoading', false)
      }
    },
    handleDownloadArchiveDocument(document) {
      this.$confirm(
        'Вы хотите скачать архивную версию документации. Загрузите актуальную версию документа для работы с корректными данными.',
        'Загрузка архивной документации',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Скачать актуальную версию',
          cancelButtonText: 'Продолжить загрузку',
          customClass: 'document-message-box'
        }).then(() => {
        this.downloadDocument(this.documentItem);
      }).catch(action => {
        if (action === 'cancel') {
          this.downloadDocument(document);
        }
      })
    },
    handleComparison() {
      this.selectDocument(this.documentItem)
      this.openDocumentComparison()
    }
  }
}
</script>

<style scoped lang="scss">
.el-button {
  padding: 4px 6px;
}

.cascader-panel {
  margin-top: 0;
  width: 100%;

  ::v-deep {
    .el-cascader-node {
      padding: 0;
      height: auto;
    }
    .no-access {
      width: 100%;
      display: block;
    }
    .el-icon-check {
      display: none;
    }
    .el-cascader-menu {
      width: 100%;
      padding: 4px 0;
    }
    .el-cascader-menu__wrap {
      margin: 0!important;
    }
    .el-cascader-menu__list {
      padding: 0;
    }
    .el-cascader-node.is-active {
      color: #606266;
      font-weight: normal;
    }
    .el-cascader-menu__wrap {
      height: auto;
      overflow: auto;
    }
    .el-cascader-node__label {
      line-height: 27px;
      padding: 0 15px;
      margin: 0;
      font-size: 13px;
    }
  }
}


.cascader-popover {

    ::v-deep {

      & {
        padding: 0;
        background: #1a9aef;
      }
  }
}

::v-deep {
  .document-list .arrow-list {
    position: relative;
    left: 10px;
  }
  .el-tooltip {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
}

</style>
