export const colors = {
  'black-pavel-1': '#333745',
  'blue-pavel-1': '#336DDE',
  'blue-pavel-2': '#409EFF',
  'green-pavel-1': '#6EC5BF',
  'green-pavel-2': '#B6E4CB',
  'purple-pavel-1': '#A96DA3',
  'red-pavel-1': '#F56C6C',
  'orange-pavel-1': '#F99E4A',
  'orange-pavel-2': '#EDAE49',
  'pink-pavel-1': '#FEBB9B',
  'yellow-pavel-1': '#FED792',
  'white-pavel-1': '#FAF3DD',
  'brown-pavel-1': '#D8BFAA'
}

export const techRoomColors = {
  'blue-pavel-2': '#409EFF',
  'green-pavel-3': '#27AE60',
  'orange-pavel-2': '#EDAE49',
  'purple-pavel-1': '#A96DA3',
  'red-pavel-1': '#F56C6C',
  'black-pavel-1': '#333745'
}

export const techRoomColorsWithLabel = {
  'blue-pavel-2': { value: '#409EFF', label: 'Синий'},
  'green-pavel-3': { value: '#27AE60', label: 'Зеленый'},
  'orange-pavel-2': { value: '#EDAE49', label: 'Желтый'},
  'purple-pavel-1': { value: '#A96DA3', label: 'Фиолетовый'},
  'red-pavel-1': { value: '#F56C6C', label: 'Красный'},
  'black-pavel-1': { value: '#333745', label: 'Черный'}
}

export const colorsByPavel = Object.entries(colors).filter(([k]) => k.includes('pavel')).map(([_, v]) => v)

export const colorsForChart = colorsByPavel.filter(x => ![colors['black-pavel-1'], colors['blue-pavel-1'], colors['green-pavel-2']].includes(x))

export const hexToRgba = (hex, alpha = 0.7) => {
  if (!hex) return
  const [r, g, b] = hex.match(/\w\w/g)
    .map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`
}
