import { compose, thenOr } from './immutable'
import { isEqual, isBefore, isAfter } from 'date-fns'

export const createDisabledDate = ({ from, to } = {}) => x => {
  const f = (k, f) => y => y && thenOr(k, f, y) 

  return !compose(
    f(to, z => isEqual(x, z) || isBefore(x, z)),
    f(from, z => isEqual(x, z) || isAfter(x, z))
  )(true)
}

export const placeFieldObjectToPathLabel = ({ house, floor, plan, work_plan } = {}) => [house, floor, plan, work_plan]
  .filter(is)
  .pluck('label')
  .join(' / ')
