<template>
    <div class="f space-x-0.25">
        <!-- Status -->
        <el-tag v-if="status.value"
                :type="status.mood"
                class="fs-0.7"
                size="mini"
                :effect="dark ? 'dark' : 'light'">
            {{ status.value }}
        </el-tag>

        <!-- Polygons count -->
        <task-polygon-stats v-if="withPolygons"
                            :value="polygonsCount"
                            :dark="dark"
                            size="mini"
                            with-finished-accepted
                            with-finished-not-accepted
                            with-all-tooltip />
    </div> 
</template>

<script>
import { getPolygonsCount } from '@/models/tasks'

import TaskPolygonStats from '@/components/tasks/TaskPolygonStats.vue'

export default {
  components: {
    TaskPolygonStats
  },
  props: {
    task: { type: Object, default: null },
    dark: { type: Boolean, default: false },

    withPolygons: { type: Boolean, default: false }
  },
  computed: {
    status() {
      const { translated_result } = this.task || {}

      let { value, mood } = translated_result || {}

      mood === 'sad' && (mood = 'info')

      return { value, mood }
    },

    polygonsCount() {
      return getPolygonsCount(this.task)
    }
  }
}
</script>
