import { millisecondsToMinutes } from 'date-fns'

import { key } from '@/utils/common'
import { parseAt, labelAt } from '@/utils/date'
import { compose, unique, itemsOr } from '@/utils/immutable'
import { extensionIcon } from '@/utils/ui'

export const treeTypes = {
  TREE_BY_APPROVAL: 'tree-by-approval',
  TREE: 'tree'
}

export const translateTreeTypes = type => ({
  [treeTypes.TREE_BY_APPROVAL]: 'СОД',
  [treeTypes.TREE]: 'Хранилище'
})[type]

export const treeGroupTypes = {
  APPROVED: 'approved',
  CHANGED: 'changed',
  REJECTED: 'rejected',
  REVIEWABLE: 'reviewable',
  REMOVED: 'removed'
}

export const translateTreeGroupTypes = type => ({
  [treeGroupTypes.APPROVED]: 'approved',
  [treeGroupTypes.CHANGED]: 'changed',
  [treeGroupTypes.REJECTED]: 'rejected',
  [treeGroupTypes.REVIEWABLE]: 'reviewable',
  [treeGroupTypes.REMOVED]: 'removed'
})[type]

export const types = {
  BIM_MODEL: 'bim_model',
  EXECUTIVE_DOCUMENTATION: 'executive_documentation',
  INITIAL_PERMITTING_DOCUMENTATION: 'initial_permitting_documentation',
  OTHER_DOCUMENTATION: 'other_documentation',
  PROJECT_DOCUMENTATION: 'project_documentation',
  PROJECT_WORK_PRODUCTION: 'project_work_production',
  WORK_DOCUMENTATION: 'work_documentation'
}

export const translateType = type => ({
  [types.BIM_MODEL]: 'BIM модели',
  [types.EXECUTIVE_DOCUMENTATION]: 'Исполнительная документация',
  [types.INITIAL_PERMITTING_DOCUMENTATION]: 'Исходно-разрешительная документация',
  [types.OTHER_DOCUMENTATION]: 'Прочая документация',
  [types.PROJECT_DOCUMENTATION]: 'Проектная документация',
  [types.PROJECT_WORK_PRODUCTION]: 'Проект производства работ',
  [types.WORK_DOCUMENTATION]: 'Рабочая документация'
})[type]

export const getVersions = document => 
  document 
    ? unique(
      [document, ...document.history || []]
        .filter(x => x.is_indexed && x.is_allowed_to_compare)
        .map(({ id, version, created_at, author, page_count }) => ({
          id,
          version,
          page_count,
          key: key(), value: version, 
          at: parseAt(created_at),
          label: `Версия ${version} от ${labelAt(created_at)}`,
          author
        })),
      (a, b) => a.version === b.version && millisecondsToMinutes(a.at.getTime()) === millisecondsToMinutes(b.at.getTime()) 
    )
    : []

export const isBim = document => document?.type === 'bim_model'
export const isComparable = document => document.history?.length && ![document, ...document.history].find(x => !x.is_indexed || !x.is_allowed_to_compare)
export const isApprovable = document => !!document?.approval_process_id
export const isArchived = document => document?.status === 'archive'
export const isBimCompleted = document => document?.bim_model?.processing_status === 'completed'
export const isBimFailed = document => document?.bim_model?.processing_status === 'error'
export const isBimToReprocessing = document => !!document?.bim_model?.can_send_for_reprocessing
export const isBimNotSupported = document => isBim(document) && document?.bim_model?.processing_type === 'autodesk'

export const getName = document => document?.original_file_name || 'Неизвестное имя'
export const getType = document => document?.translated_type || 'Неизвестный тип'
export const getIcon = document => extensionIcon(document?.original_file_name)
export const getVersion = document => document?.version
export const getProperty = (document, key) => document?.data?.[key]

export const getApprovalTask = document => document?.approval_task || document?.approval_process
export const getApprovalMainDocument = document => (document?.approval_process || document?.approval_task)?.data?.main_document 
export const getApprovalAccompanyingDocuments = document => (document?.approval_process || document?.approval_task)?.data?.accompanying_documents
export const getApprovalDocumentType = document => document?.approval_process_document_type
                                        

export const getExtension = document => (document.original_file_name || '').match(/\.([0-9a-z]+)$/i)?.[1]
export const hasExtensionOf = (document, ...extensions) => (extensions || []).includes(getExtension(document))

export const getAuthorName = document => compose(itemsOr)({
  if: [
    document?.author?.last_name && document.author.last_name,
    document?.author?.first_name && document.author.first_name[0].toUpperCase() + '.',
    document?.author?.patronymic && document.author.patronymic[0].toUpperCase() + '.'
  ].filter(x => x).join(' '),
  else: document?.author?.name || 'Неизвестный автор'
})

export const getBimStatusAsTag = (document = {}) => {
  const { processing_status, translated_processing_status } = document.bim_model

  const at = { type: 'info', label: translated_processing_status }

  let r

  r ||= processing_status === 'error' && { ...at, type: 'danger' }
  r ||= processing_status === 'in_queue' && { ...at, type: 'warning' }
  r ||= processing_status === 'processing' && { ...at, type: 'warning' }
  r ||= processing_status === 'waiting_sent' && { ...at, type: 'warning' }
  r ||= processing_status === 'completed' && {}
  r ||= at

  return r
}
