import {union} from 'lodash';
import moment from 'moment';
import * as projectStructureUtils from '@/utils/project-structure';
import { toDateRangeForServer } from '@/utils/datetime';
import { previousMonday, startOfDay, endOfDay } from 'date-fns';
import { actionable, loadable } from '@/store/mixins'
import axios from 'axios';
import { formatForServer, parse } from '@/utils/date';

export default {
  namespaced: true,
  mixins: [
    actionable({
      name: 'changeDocument',
      at: ({ state }, { id, bim_file, processing_status, translated_processing_status }) => 
        state.projectDocumentIndex = state.projectDocumentIndex.map(x => x.id === id ? {
          ...x,
          bim_model: {
            ...x.bim_model,
            bim_file,
            processing_status,
            translated_processing_status
          }
        } : x)
    }),

    loadable({ name: 'structure', action: 'getProjectStructure' })
  ],
  state: () => ({
    projectUnreadTasksCount: 0,
    lastProjectStatisticRequestId: null,
    structure: {},
    projectTree: [],
    projectUrl: null,
    selectedPlan: {},
    projectHouses: [],
    projectFloors: [],
    projectPlans: [],
    projectRooms: [],
    projectPoints: [],
    projectPlanPoints: [],
    projectRoomPoints: [],
    projectDocTemplates: [],
    projectLastDocNumbers: [],
    lastSelectedProject: null,
    selectedStatistic: projectStructureUtils.STATISTICS.CHANGES,
    filterOptions: {
      houses: [],
      housing: [],
      sections: [],
      floors: [],
      jobs: []
    },
    filter: {
      recognitions_count: [previousMonday(previousMonday(new Date())), new Date()]
    },
    statisticLoading: false,
    jobs: [],
    projectDocumentIndex: [],
    projectDocumentPagination: {},
    projectDocumentLoading: false,

    statistics: null
  }),
  getters: {
    project: state => state.structure,
    projectId: state => state.structure?.id,
    projectLoaded: state => !!state.structure?.id,
    selectedPlan: state => state.selectedPlan,
    hasTechnicalPlan: state => state.selectedPlan && state.selectedPlan.type === 'technical',
    statistics: state => state.statistics,
    projectDocumentLoading: state => state.projectDocumentLoading,

    projectFilter: (state) => (filterPage) => {
      let filter = {...state.filter};
      let dateFrom = null;
      let dateTo = null;
      let hasDates = !!filter.recognitions_count;
      if (hasDates) {
        const [from, to] = toDateRangeForServer(filter.recognitions_count)

        dateFrom = from
        dateTo = to

        delete filter.recognitions_count;
      } else {
        delete filter.recognitions_count;
      }
      switch (filterPage) {
      case 'photos': {
        filter['image.shot_at_from'] = hasDates ? dateFrom : null;
        filter['image.shot_at_to'] = hasDates ? dateTo : null;
        delete filter.recognized_objects_count_from;
        delete filter.recognized_objects_count_to;
        return filter;
      }
      case 'structure': {
        filter['recognized_objects_count_from'] = hasDates ? dateFrom : null;
        filter['recognized_objects_count_to'] = hasDates ? dateTo : null;
        return filter;
      }
      case 'plan': {
        return {
          marked: filter.marked,
          recognized: filter.recognized,
          recognized_objects_count_from: dateFrom,
          recognized_objects_count_to: dateTo,
          'planItemType.id': filter['planItemType.id']
        }
      }
      default: {
        return filter;
      }
      }

    },
    projectName: (state) => {
      return state.structure.name;
    },
    projectTableau: (state) => {
      return state.projectUrl;
    },
    lastProjectStatisticRequestId: (state) => {
      return state.lastProjectStatisticRequestId;
    },
    projectDocTemplatesByType: (state) => (docType) => {
      return state.projectDocTemplates.filter(item => item.type === docType);
    },
    selectedProject: (state) => {
      return (({
        id,
        name
      }) => ({
        id,
        name
      }))(state.structure);

    },
    floorIdByPlanId: (state) => (planId) => {
      let result = null;
      if (state.structure.houses) {
        state.structure.houses.find(house => {
          return !!house.floors.find(floor => {
            return !!floor.floor_plans.find(plan => {
              if (plan.id === planId) {
                result = floor.id;
              }
              return !!result;
            });
          });
        });
      }
      return result;
    },
    structure: state => state.structure,
    /**
     * This method for project structure tree in aside panel
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @returns {*[]|*}
     */
    structureTree(state, getters, rootState, rootGetters) {
      if (rootGetters['auth/permissions']) {
        return projectStructureUtils.projectStructureMaker(state.structure, rootGetters['auth/permissions']);
      } else {
        return [];
      }
    },
    /**
     * This method for cascader
     * @param state
     * @returns {*|*[]}
     */
    projectTree(state) {
      return state.structure.houses ? state.structure.houses.map(house => ({
        id: house.id,
        value: house.id,
        label: 'Строение ' + house.number,
        children: house.floors ? house.floors.map(floor => ({
          id: floor.id,
          value: floor.id,
          label: 'Этаж ' + floor.number,
          children: floor.floor_plans ? floor.floor_plans.map(plan => ({
            id: plan.id,
            value: plan.id,
            type: {
              technical: 'technical_plan',
              common: 'common_plan'
            }[plan.type],
            label: plan.expired_at
              ? (
                plan.type === 'technical' ? 'Технический план от ' +
                  plan.expired_at.substr(0, 10) : 'План от ' +
                  plan.expired_at.substr(0, 10)
              )
              : '',
            children: plan.defining_points ? plan.defining_points.sort(
              projectStructureUtils.makeSortDefiningPointsByName())
              .map(point => ({
                id: point.id,
                value: point.id,
                project_id: state.structure.id,
                house_id: house.id,
                floor_id: floor.id,
                leaf: true,
                label: 'Точка ' + point.name,
                type: 'plan_defining_point'
              })) : []
          })) : []
        })) : []
      })) : [];
    },
    selectedStatistic(state) {
      return state.selectedStatistic;
    },
    statisticLoading(state) {
      return state.statisticLoading
    },
    jobs: state => state.jobs,
    filter: state => state.filter,
    preparedDocumentPagination: state => {
      return state.projectDocumentPagination
    },
    preparedDocumentIndex: state => {
      return state.projectDocumentIndex.map(item => {
        const {id, history} = item;
        if (history) {
          history.forEach(historyItem => {
            historyItem.isChildren = true,
            historyItem.activeVersionId = id
          })
        }
        return {
          children: history,
          ...item
        }
      })
    },
    previewList: state => {
      return state.projectDocumentIndex.filter(item => item?.file_storage_path).map(item => `${process.env.VUE_APP_HOSTNAME}/cropler/v1/1000/0/${item?.file_storage_path}`)
    }
  },

  mutations: {
    SET_STATISTICS: (state, points) => {
      if (!state.structure) {
        return
      }

      const statistics = points.reduce((result, point) => ({ ...result, [point.plan_id]: { ...result[point.plan_id], [point.id]: point } }), {})

      state.structure = projectStructureUtils.prepareProjectStructureStatistics(state.structure, state.statistics = statistics)
      state.lastSelectedProject = state.structure.id;
    },

    SET_FILTER: (state, payload) => {
      state.filter = payload;
    },
    SET_LAST_PROJECT_STATISTIC_REQUEST_ID: (state, payload) => {
      state.lastProjectStatisticRequestId = payload;
    },
    SET_SELECTED_PLAN: (state, payload) => {
      state.selectedPlan = payload;
    },
    SET_PROJECT_DOC_TEMPLATES: (state, payload) => {
      state.projectDocTemplates = payload;
    },
    SET_PROJECT_STRUCTURE: (state, project = {}) => {
      state.structure = projectStructureUtils.prepareProjectStructureStatistics(project);
      state.lastSelectedProject = project.id;
    },
    SET_PROJECT_URL: (state, projectUrl) => {
      state.projectUrl = projectUrl;
    },
    CLEAR_PROJECT(state) {
      state.structure = {};
      state.projectTree = {};
      state.projectUrl = null;
    },
    SET_PROJECT_HOUSES: (state, payload) => {
      state.projectHouses = union(state.projectHouses, payload);
    },
    SET_HOUSE_FLOORS: (state, payload) => {
      state.projectFloors = union(state.projectFloors, payload);
    },
    SET_FLOOR_PLANS: (state, payload) => {
      state.projectPlans = union(state.projectPlans, payload);
    },
    SET_PLAN_ROOMS: (state, payload) => {
      state.projectRooms = union(state.projectRooms, payload);
    },
    SET_PLAN_POINTS: (state, payload) => {
      state.projectPlanPoints = union(state.projectPlanPoints, payload);
    },
    SET_ROOM_POINTS: (state, payload) => {
      state.projectRoomPoints = union(state.projectRoomPoints, payload);
    },
    SET_PROJECT_UNREAD_TASKS_COUNT: (state, payload) => {
      state.projectUnreadTasksCount = payload.unread_tasks_count;
    },
    SET_PROJECT_LAST_DOC_NUMBERS: (state, payload) => {
      state.projectLastDocNumbers = Object.entries(payload).map(item => ({
        key: item[0],
        value: `${item[1]}`
      }));
    },
    SET_SELECTED_STATISTIC: (state, payload) => {
      state.selectedStatistic = payload;
    },
    START_STATISTIC_LOADING: (state) => {
      state.statisticLoading = true
    },
    STOP_STATISTIC_LOADING: (state) => {
      state.statisticLoading = false
    },
    SET_FILTER_OPTIONS: (state, payload) => {
      let filter = projectStructureUtils.filterOptionsByProjectStructure(payload);
      Object.keys(filter).forEach(key => {
        state.filterOptions[key] = filter[key];
      })
    },
    SET_JOBS: (state, jobs) => state.jobs = jobs,
    SET_PROJECT_DOCUMENT_INDEX: (state, payload) => state.projectDocumentIndex = payload,
    SET_PROJECT_DOCUMENT_PAGINATION: (state, payload) => state.projectDocumentPagination = payload,
    SET_DOCUMENTS_LOADING: (state, payload) => state.projectDocumentLoading = payload
  },

  actions: {
    fetchProject: async function ({ commit }, id) {
      const { data } = await this.$api.project.structure(id, {
        params: {
          append: [
            'floor_work_plans'
          ]
        }
      });
      const url = await this.$api.project.get(id);

      commit('SET_PROJECT_STRUCTURE', data.data)
      commit('SET_PROJECT_URL', url.data.data.tableau_url)
      commit('SET_FILTER_OPTIONS', data.data)
    },

    fetchStructureStatistics: async function ({ commit, getters }, { projectId, payload = {} }) {
      commit('START_STATISTIC_LOADING');
      commit('SET_LAST_PROJECT_STATISTIC_REQUEST_ID', projectId);

      const points = await this.$api.project.getStructureStatistics(projectId, payload);

      if (projectId === getters.lastProjectStatisticRequestId) {
        commit('SET_STATISTICS', points);
      }
      commit('STOP_STATISTIC_LOADING');
    },

    // NOT REVIEWED

    setFilter: async function ({commit}, payload) {
      await commit('SET_FILTER', payload);
    },
    setSelectedPlan: function({commit},payload){
      commit('SET_SELECTED_PLAN',payload);
    },
    getProjectDocTemplates: async function ({commit}, {
      projectId,
      payload
    }) {
      let {data} = await this.$api.project.getProjectDocTemplates(projectId, payload);
      commit('SET_PROJECT_DOC_TEMPLATES', data.data);
      return data;
    },
    getProjectLastDocNumbers: async function ({commit}, {projectId}) {
      let {data} = await this.$api.project.getProjectLastNumbers(projectId);
      commit('SET_PROJECT_LAST_DOC_NUMBERS', data.data);
      return data;
    },
    getProjectUsers: async function (_, {
      projectId,
      payload
    }) {
      let {data} = await this.$api.project.getProjectUsers(projectId, payload);
      return data;
    },
    getProjectHouses: async function ({commit}, {
      projectId,
      payload
    }) {
      let {data} = await this.$api.project.getProjectHouses(projectId, payload);
      commit('SET_PROJECT_HOUSES', data.data);
      return data;
    },
    getHouseFloors: async function ({commit}, {
      houseId,
      payload
    }) {
      let {data} = await this.$api.houses.getHouseFloors(houseId, payload);
      commit('SET_HOUSE_FLOORS', data.data);
      return data;
    },
    getFloorPlans: async function ({commit}, {
      floorId,
      payload
    }) {
      let {data} = await this.$api.floors.getFloorPlans(floorId, payload);
      commit('SET_FLOOR_PLANS', data.data);
      return data;
    },
    getFloorRooms: async function ({commit}, {
      floorId,
      payload
    }) {
      let {data} = await this.$api.floors.getFloorRooms(floorId, payload);
      commit('SET_PLAN_ROOMS', data.data);
      return data;
    },
    getPlanPoints: async function ({commit}, {
      planId,
      payload
    }) {
      let {data} = await this.$api.floorPlans.getPlanPoints(planId, payload);
      commit('SET_PLAN_POINTS', data.data);
      return data;
    },
    getRoomPoints: async function ({commit}, {
      roomId,
      payload
    }) {
      let {data} = await this.$api.rooms.getRoomPoints(roomId, payload);
      commit('SET_ROOM_POINTS', data.data);
      return data;
    },

    getProjectUnreadTasksCount: async function (
      {commit}, {
        projectId,
        payload
      }) {
      let {data} = await this.$api.project.getProjectUnreadTasksCount(projectId,
        payload);
      commit('SET_PROJECT_UNREAD_TASKS_COUNT', data.data);
      return data;
    },

    fetchJobs: async function({ commit }, { projectId }) {
      const jobs = (await this.$api.dirsV2.getJobsByProject({ project: { project_id: projectId }, withDeleted: true }))?.data?.data?.data || []
      commit('SET_JOBS', jobs)
    },

    // TODO@refactor: Opyat' govnishe, da chto ze takoe, Inochkin
    loadProjectLazy: async function (store, {
      projectId,
      node,
      resolve,
      nestedLevelLoading = 6,
      preloadLevel = 0
    }) {

      if (node.level === 0) {
        try {
          let {data: housesResponse} = await store.dispatch('getProjectHouses', {projectId: projectId});
          let houses = await Promise.all(housesResponse.map(async house => {
            let floors = [];
            if (preloadLevel === 1) {
              let floorResponse = await store.dispatch('getHouseFloors', {
                houseId: house.id, 
                payload: {
                  params: {
                    sort: 'sort_number,-created_at'
                  }
                }})

              floors = floorResponse.data.map(floor => {
                return {
                  id: floor.id,
                  value: floor.id,
                  label: floor.number,
                  type: 'floor',
                  children: [],
                  leaf: node.level === preloadLevel - 1
                };
              });
            }

            return {
              id: house.id,
              value: house.id,
              label: `${house.street} ${house.number ? house.number : '' } ${house.housing ? 'к'+house.housing : '' }`,
              type: 'house',
              icon: house.type,
              children: floors,
              leaf: node.level === nestedLevelLoading
            };
          }))

          resolve(houses);
        } catch (e) {
          resolve([]);
        }
        return
      }
      switch (true) {
      case node.data.type === 'house' && node.level <= nestedLevelLoading:
        if (node.children && node.children.length) {
          resolve();
          return
        }
        store.dispatch('getHouseFloors', {
          houseId: node.data.id,
          payload: {
            params: {
              sort: 'sort_number,-created_at'
            }
          }
        })
          .then(response => {
            let floors = response.data.map(floor => {
              return {
                id: floor.id,
                value: floor.id,
                label: floor.number,
                type: 'floor',
                children: [],
                leaf: node.level === nestedLevelLoading
              };
            });
  
            resolve(floors);
          })
          .catch(() => {
            resolve([]);
          });
        return;
      case node.data.type === 'floor' && node.level <= nestedLevelLoading:
        if (node.children && node.children.length) {
          resolve();
          return
        }
        store.dispatch('getFloorPlans', {floorId: node.data.id})
          .then(response => {
            let floorPlans = response.data.map(plan => {
              const dateFormat = moment.utc(plan.created_at)
                .local()
                .locale('ru')
                .format('DD.MM.YY');
              return {
                id: plan.id,
                value: plan.id,
                label: plan.name ? plan.name : `${plan.translated_type} план от ${dateFormat}`,
                type: plan.type === 'technical'
                  ? 'technical_plan'
                  : 'common_plan',
                children: [],
                leaf: node.level === nestedLevelLoading
              };
            });
            resolve(floorPlans);
          })
          .catch(() => {
            resolve([]);
          });
        return;
      case node.data.type === 'technical_plan' && node.level <= nestedLevelLoading:
        if (node.children && node.children.length) {
          resolve();
          return
        }
        store.dispatch('getFloorRooms', {floorId: node.parent.data.id})
          .then(response => {
            let rooms = response.data.map(room => {
              return {
                id: room.id,
                value: room.id,
                label: `Помещение №${room.number}`,
                type: 'room',
                children: [],
                leaf: node.level === nestedLevelLoading
              };
            });
            resolve(rooms);
          })
          .catch(() => {
            resolve([]);
          });
        return;
      case node.data.type === 'common_plan' && node.level <= nestedLevelLoading:
        if (node.children && node.children.length) {
          resolve();
          return
        }
        store.dispatch('getPlanPoints', {planId: node.data.id})
          .then(response => {
            let points = response.data.sort(
              projectStructureUtils.makeSortDefiningPointsByName())
              .map(point => {
                return {
                  id: point.id,
                  value: point.id,
                  label: `Точка ${point.name}`,
                  type: 'defining_point',
                  children: [],
                  leaf: true
                };
              });
            resolve(points);
          })
          .catch(() => {
            resolve([]);
          });
        return;
      case node.data.type === 'room' && node.level <= nestedLevelLoading:
        if (node.children && node.children.length) {
          resolve();
        }
        store.dispatch('getRoomPoints', {roomId: node.data.id})
          .then(response => {
            let roomPoints = response.data.sort(
              projectStructureUtils.makeSortDefiningPointsByName())
              .map(point => {
                return {
                  id: point.id,
                  value: point.id,
                  label: `Точка ${point.name}`,
                  type: 'defining_point',
                  children: [],
                  leaf: true
                };
              });
            resolve(roomPoints);
          })
          .catch(() => {
            resolve([]);
          });
        return;
      case node.data.type === 'defining_point' && node.level <= nestedLevelLoading:
        resolve();
        return;
      }

      if (!node.leaf) {
        resolve();
      }
    },
    getProjectStructure: async function ({commit}, id) {
      let {data} = await this.$api.project.structure(id, {
        params: {
          append: [
            'floor_work_plans'
          ]
        }
      });
      const url = await this.$api.project.get(id);
      
      commit('SET_PROJECT_STRUCTURE', data.data);
      commit('SET_PROJECT_URL', url.data.data.tableau_url);
      commit('SET_FILTER_OPTIONS', data.data);
    },
    // TODO add payload
    getProjectStatisticsAfterStructure: async function ({dispatch}, {
      projectId,
      payload = {}
    }) {
      await dispatch('getProjectStructure', projectId);
      await dispatch('fetchStructureStatistics', {
        projectId: projectId,
        payload: payload
      });
    },
    clearProject({ commit, dispatch }) {
      commit('CLEAR_PROJECT');
      dispatch('projects/clearProject', null, { root: true })
    },
    getProjectDocumentDownloadedHistory: async function({commit}, {projectId, payload}) {
      let {data} = await this.$api.projectsV2.getProjectDocumentDownloadedHistory(projectId, payload);
      return data.data;
    },
    filterProjectDocuments: async function({ commit, getters }, { data }) {
      commit('SET_PROJECT_DOCUMENT_INDEX', data.data);
      commit('SET_PROJECT_DOCUMENT_PAGINATION', data.meta);
      commit('SET_DOCUMENTS_LOADING', false);
      return data.data;
    },
    getProjectDocumentIndex: async function({ commit, getters }, {projectId, type, folderId, page, from, to, sort, clear = true}) {
      const cancelTokenSource = axios.CancelToken.source()

      if(getters.projectDocumentLoading) getters.projectDocumentLoading.cancel()
      commit('SET_DOCUMENTS_LOADING', cancelTokenSource);

      if (clear) commit('SET_PROJECT_DOCUMENT_INDEX', []);

      const params = {
        page: { number: page },
        filter: {
          type: 'local',
          ...type && { document_type: type },
          ...folderId && { folder_id: folderId },
          ...from && { createdAtFrom: formatForServer(startOfDay(parse(from, { withTime: false }))) },
          ...to && { createdAtTo: formatForServer(endOfDay(parse(to, { withTime: false }))) }
        }, 
        ...sort && { sort }   
      }

      let {data} = await this.$api.projectsV2.getProjectDocumentIndex(projectId, { params, cancelToken: cancelTokenSource.token });
      commit('SET_PROJECT_DOCUMENT_INDEX', data.data);
      commit('SET_PROJECT_DOCUMENT_PAGINATION', data.meta);
      commit('SET_DOCUMENTS_LOADING', false);
      return data.data;
    },
    uploadProjectDocument: async function(context, {projectId, payload, config}) {
      let {data} = await this.$api.projectsV2.uploadProjectDocument(projectId, payload, config);
      return data;
    },
    deleteProjectDocument: async function(context, {projectId, projectDocumentId}) {
      let {data} = await this.$api.projectsV2.deleteProjectDocument(projectId, projectDocumentId);
      return data;
    },
    downloadProjectDocument: async function(context, {projectId, projectDocumentId}) {
      let {data} = await this.$api.projectsV2.downloadProjectDocument(projectId, projectDocumentId);
      return data;
    },
    downloadBimModelForView: async function(context, {projectId, projectDocumentId, fileName}) {
      let {data} = await this.$api.projectsV2.downloadBimModelForView(projectId, projectDocumentId, fileName);
      return data;
    },
    createProjectDocument: async function(context, {projectId, payload}) {
      let {data} = await this.$api.projectsV2.createProjectDocument(projectId, payload);
      return data;
    },
    updateProjectDocument: async function(context, {projectId, projectDocumentId, payload}) {
      let {data} = await this.$api.projectsV2.updateProjectDocument(projectId, projectDocumentId, payload);
      return data;
    },
    upgradeProjectDocument: async function(context, {projectId, projectDocumentId, payload}) {
      let {data} = await this.$api.projectsV2.upgradeProjectDocument(projectId, projectDocumentId, payload);
      return data;
    }
  }
};
