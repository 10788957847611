import { VIEW_MODES, FEATURE_TYPES } from '@/utils/plan';
import { symmetricalDifference } from '@/utils/immutable';

import { isPoint, isWorkPolygon } from '@/models/shapes'

const isManagedPoint = ({ type }) => [FEATURE_TYPES.CAMERA360, FEATURE_TYPES.QUADCOPTER, FEATURE_TYPES.FACADE].includes(type)

const initialState = () => ({
  viewMode: [VIEW_MODES.POINT],
  markupType: {
    featureType: '',
    geometryType: ''
  },
  markupMode: false,

  isLegend: false,
  isComments: false,
  isTransformation: false,
  isCluster: true,
  cancelledTransformation: false,

  selectedFeature: null,
  selectedEntity: null,

  selectedFeatures: [],
  selectedEntities: [],

  hittedFeatures: [],

  multiple: false,

  premisesClassesVisibility: false
})

export default {
  namespaced: true,

  state: () => initialState(),

  getters: {
    selectedFeature: state => state.selectedFeatures[0],
    selectedEntity: state => state.selectedEntities[0],
    selectedFeatures: state => state.selectedFeatures,
    selectedEntities: state => state.selectedEntities,

    hittedFeatures: state => state.hittedFeatures,
  
    selectedPoint: state => state.selectedFeatures.filter(isPoint).map(feature => feature.getProperties())[0],
    selectedPoints: state => state.selectedFeatures.filter(isPoint).map(feature => feature.getProperties()),
    selectedSavedPoints: state => state.selectedFeatures.filter(isPoint).filter(feature => feature.get('id')).map(feature => feature.getProperties()),
    selectedWorkPolygons: state => state.selectedFeatures.filter(isWorkPolygon).map(feature => feature.get('polygon')),

    isSelectedFeature: state => feature => !!state.selectedFeatures.find(each => 
      (each.get('id') && feature.get('id') && each.get('id') === feature.get('id')) || each.get('name') === feature.get('name')),

    isViewModeActive: state => viewModeType => state.viewMode.includes(viewModeType),
    isMarkupTypeActive: state => markupType => state.markupType.featureType === markupType && state.markupMode,

    isCalibration: state => state.markupType.featureType === FEATURE_TYPES.RULER_CALIBRATION,
    isLength: state => state.markupType.featureType === FEATURE_TYPES.RULER_LENGTH,
    isArea: state => state.markupType.featureType === FEATURE_TYPES.RULER_AREA,
    isOrientation: state => state.markupType.featureType === FEATURE_TYPES.RULER_ORIENTATION,

    isCamera: state => state.markupType.featureType === FEATURE_TYPES.CAMERA360,
    isQopter: state => state.markupType.featureType === FEATURE_TYPES.QUADCOPTER,
    isFacade: state => state.markupType.featureType === FEATURE_TYPES.FACADE,
    isDefect: state => state.markupType.featureType === FEATURE_TYPES.DEFECT,
    isMonitor: state => state.markupType.featureType === FEATURE_TYPES.MONITOR,

    isRoom: state => state.markupType.featureType === FEATURE_TYPES.ROOM,
    isTechRoom: state => state.markupType.featureType === FEATURE_TYPES.TECH_ROOM,
    isProtocol: state => state.markupType.featureType === FEATURE_TYPES.PROTOCOL,
    isPremises: state => state.markupType.featureType === FEATURE_TYPES.PREMISES && state.markupMode,
    isIntermediateAcceptance: state => state.markupType.featureType === FEATURE_TYPES.INTERMEDIATE_ACCEPTANCE && state.markupMode,
    isFinalAcceptance: state => state.markupType.featureType === FEATURE_TYPES.FINAL_ACCEPTANCE && state.markupMode,
    
    isWorkPolygon: state => state.markupType.featureType === FEATURE_TYPES.WORK_POLYGON_NEW && state.markupMode,
    
    isPointMode: state => state.viewMode.includes(VIEW_MODES.POINT) && !state.viewMode.includes(VIEW_MODES.ROOM),
    isRoomMode: state => state.viewMode.includes(VIEW_MODES.ROOM),
    isTechRoomMode: state => state.viewMode.includes(VIEW_MODES.TECH_ROOM),
    isProtocolMode: state => state.viewMode.includes(VIEW_MODES.PROTOCOL),
    isPremisesMode: state => state.viewMode.includes(VIEW_MODES.PREMISES),
    isAcceptanceMode: state => state.viewMode.includes(VIEW_MODES.ACCEPTANCE),

    isLegend: state => state.isLegend,
    isComments: state => state.isComments,
    isTransformation: state => state.isTransformation,
    isCluster: state => state.isCluster,
    cancelledTransformation: state => state.cancelledTransformation,

    hasSelectedPoints: state => !!state.selectedFeatures.filter(isPoint).length,
    hasSingleSelectedPoint: state => state.selectedEntities.length === 1 && isManagedPoint(state.selectedEntities[0]),

    hasSelectedSavedPoint: state => 
      state.selectedEntity 
      && [FEATURE_TYPES.CAMERA360, FEATURE_TYPES.QUADCOPTER, FEATURE_TYPES.FACADE, FEATURE_TYPES.DEFECT].includes(state.selectedEntity['type'])
      && state.selectedEntity.id,

    hasSelectedDefect: state => state.selectedEntity && state.selectedEntity['type'] === FEATURE_TYPES.DEFECT,

    hasSelectedPhoto: state => state.selectedEntity && state.selectedEntity['photoId'],

    premisesClassesVisibility: state => state.premisesClassesVisibility
  },

  mutations: {
    SET_MARKUP_TYPE: (state, {featureType, geometryType}) => {
      state.markupType.featureType = featureType
      state.markupType.geometryType = geometryType
    },
    SET_MARKUP_MODE: (state, payload) => {
      state.markupMode = payload;
    },
    TOGGLE_VIEW_MODE: (state, payload) => {
      let index = state.viewMode.indexOf(payload);
      if (index === -1) {
        state.viewMode.push(payload);
      } else {
        state.viewMode.splice(index, 1);
      }
    },
    ADD_VIEW_MODE: (state, payload) => {
      if (!state.viewMode.includes(payload))
        state.viewMode.push(payload);
    },
    REMOVE_VIEW_MODE: (state, payload) => {
      if (state.viewMode.includes(payload)) {
        state.viewMode.splice(state.viewMode.indexOf(payload), 1);
      }
    },
    CLEAR_STATE: (state) => {
      Object.assign(state,initialState());
    },

    TOGGLE_LEGEND: state => state.isLegend = !state.isLegend,
    TOGGLE_COMMENTS: state => state.isComments = !state.isComments,
    TOGGLE_TRANSFORMATION: state => { 
      state.isTransformation = !state.isTransformation
      state.cancelledTransformation = false
    },
    CANCEL_TRANSFORMATION: state => { 
      state.isTransformation = false
      state.cancelledTransformation = true
    },
    TOGGLE_CLUSTER: state => state.isCluster = !state.isCluster,

    TOGGLE_FEATURES: (state, features) => {
      state.multiple
        ? state.selectedFeatures = symmetricalDifference(state.selectedFeatures, features, (a, b) => 
          (a.get('id') && b.get('id') && a.get('id') === b.get('id')) || a.get('name') === b.get('name')) 
        : state.selectedFeatures = features

      state.selectedEntities = state.selectedFeatures.map(x => x.getProperties())
    },

    SET_SELECTED_FEATURES: (state, features) => {
      state.selectedFeatures = features
      state.selectedEntities = state.selectedFeatures.map(x => x.getProperties())
    },

    SET_HITTED_FEATURES: (state, features) => state.hittedFeatures = features,

    SET_MULTIPLE: (state, value) => state.multiple = value,

    SET_PREMISES_CLASSES_VISIBILITY: (state, value) => state.premisesClassesVisibility = value
  },

  actions: {
    setMarkupType: async function ({commit}, {
      featureType,
      geometryType
    }) {
      await commit('SET_MARKUP_TYPE', {
        featureType,
        geometryType
      });
    },
    clearMarkupType: async function ({commit}) {
      await commit('SET_MARKUP_TYPE', {
        featureType: '',
        geometryType: ''
      });
    },
    clearState: async function ({commit}) {
      await commit('CLEAR_STATE')
    },
    setMarkupMode: async function ({commit}, enabled) {
      await commit('SET_MARKUP_MODE', enabled);
    },
    toggleViewMode:async function({commit},payload){
      await commit('TOGGLE_VIEW_MODE',payload)
    },
    addViewMode:async function({commit},payload){
      await commit('ADD_VIEW_MODE',payload)
    },
    removeViewMode:async function({commit},payload){
      await commit('REMOVE_VIEW_MODE',payload)
    },

    toggleLegend: ({ commit }) => commit('TOGGLE_LEGEND'),
    toggleComments: ({ commit }) => commit('TOGGLE_COMMENTS'),
    toggleTransformation: ({ commit }) => commit('TOGGLE_TRANSFORMATION'),
    cancelTransformation: ({ commit }) => commit('CANCEL_TRANSFORMATION'),

    toggleCluster: ({ commit }) => commit('TOGGLE_CLUSTER'),
    toggleFeatures: ({ commit }, features) => commit('TOGGLE_FEATURES', features),
    selectFeatures: ({ commit }, features) => commit('SET_SELECTED_FEATURES', features),

    setHittedFeatures: ({ commit }, features) => commit('SET_HITTED_FEATURES', features),

    setMultiple: ({ commit }, value) => commit('SET_MULTIPLE', value),

    setPremisesClassesVisibility: ({ commit }, value) => commit('SET_PREMISES_CLASSES_VISIBILITY', value)
  }
}
