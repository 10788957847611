import { labelAt } from '@/utils/date'

export const planTypes = {
  Common: 'common',
  Tech: 'technical',
  Work: 'work'
}

export const planUnits = {
  M2: 'square_metre',
  M3: 'cubic_metre'
}

export const polygonStatuses = {
  New: 'new',
  Changed: 'changed',
  Unchanged: 'unchanged'
}

export const polygonWorkStatuses = {
  Created: 'created',
  AtWork: 'at_work',
  Completed: 'completed',
  CompletedPartially: 'completed_partially',
  WithProblem: 'with_problem',
  WithWarning: 'with_warning'
}

export const polygonAcceptanceStatuses = {
  FinishedAccepted : 'finished_accepted',
  FinishedNotAccepted: 'finished_not_accepted',
  NotProcessed: 'not_processed'
} 

export const getPlanLabel = plan => plan && (plan.name || `План от ${labelAt(plan.created_at, { withTime: false })}`)

export const getDelta = plan => parseFloat(plan?.scale_delta || 1)

export const getNorth = plan => plan?.north_vector

export const getWorkLayerName = layer => layer?.translated_type

export const getWorkPolygonName = (x, { layer } = {}) => [getWorkPolygonWorkTypeName(x), getWorkLayerName(layer), 'Полигон'].find(is)

export const getWorkPolygonColor = (polygon, { layer, acceptance, acceptable } = {}) => {
  let r

  r ||= acceptable && getWorkPolygonColorForAcceptable(polygon.acceptance_result)
  r ||= acceptance && getWorkPolygonColorForAccceptance(polygon.acceptance_result)
  r ||= isWorkLayerConfirmed(layer) 
    ? getWorkPolygonColorByWorkStatus(polygon.data?.work_status) 
    : getWorkPolygonColorByStatus(polygon.status)

  return r
}

export const getWorkPolygonColorByStatus = x => x && {
  [polygonStatuses.New]: 'red-pavel-1',
  [polygonStatuses.Changed]: 'orange-pavel-1',
  [polygonStatuses.Unchanged]: 'green-2'
}[x]

export const getWorkPolygonColorByWorkStatus = x => x && {
  [polygonWorkStatuses.Created]: 'blue-pavel-2',
  [polygonWorkStatuses.AtWork]: 'iris-pavel-1',
  [polygonWorkStatuses.Completed]: 'green-pavel-3',
  [polygonWorkStatuses.CompletedPartially]: 'orange-pavel-3',
  [polygonWorkStatuses.WithProblem]: 'red-pavel-2',
  [polygonWorkStatuses.WithWarning]: 'purple-pavel-2'
}[x]

export const getWorkPolygonColorForAccceptance = x => ({
  [polygonAcceptanceStatuses.FinishedAccepted]: 'orange-pavel-2',
  [polygonAcceptanceStatuses.FinishedNotAccepted]: 'orange-pavel-2',
  [polygonAcceptanceStatuses.NotProcessed]: 'orange-pavel-2',
  [undefined]: 'blue-pavel-2'
})[x]

export const getWorkPolygonColorForAcceptable = x => ({
  [polygonAcceptanceStatuses.FinishedAccepted]: 'green-pavel-3',
  [polygonAcceptanceStatuses.FinishedNotAccepted]: 'red-pavel-2',
  [polygonAcceptanceStatuses.NotProcessed]: 'orange-pavel-2',
  [undefined]: 'blue-pavel-2'
})[x]

export const getWorkPolygonWorkTypeName = x => x && x.work_types?.[0]?.name


export const isWorkLayerInProcess = workLayer => workLayer?.status === 'extracting'
export const isWorkLayerInConfirm = workLayer => workLayer?.status === 'waiting_confirmation'
export const isWorkLayerFailed = workLayer => workLayer?.status === 'extract_error'
export const isWorkLayerConfirmed = workLayer => workLayer?.status === 'confirmed'

export const isWorkPolygonBelongToLayer = (polygon, layer) => polygon && layer && polygon.layer_id === layer.id

export const isWorkPolygonHasLastAcceptanceWorkResult = x => x && ![
  x['last_acceptance_work_status'] !== undefined,
  x['last_acceptance_work_state'] !== undefined,
  x['last_acceptance_work_translated_result'] !== undefined
].some(x => !x)
export const getWorkPolygonLastAcceptanceWorkState = x => x?.['last_acceptance_work_state']

export const isPointBelongToPlan = (point, plan) => point && plan && point.plan_id === plan.id

export const isLotDefectDifferenceByWorkLayerType = (a, b) => {
  const to = x => x?.data?.corresponding_floor_work_plan_layer_type?.value

  return to(a) !== to(b)
}

export const toFilterForServer = (x, { acceptance } = {}) => {
  const { 
    polygonIds, 
    workTypes, 
    workStatus,
    inspector, 
    inspectorOrganization, 
    withInspector,
    withoutInspector,
    withWorkType,
    withoutWorkType,
    withAcceptable,
    mailingId
  } = x || {}

  const r = {
    ...polygonIds && { id: polygonIds },

    ...workTypes && { work_type_id: workTypes },
    ...workStatus && { work_status: workStatus },

    ...inspector && { inspector_user_id: inspector.id },
    ...inspectorOrganization && { inspector_organization_id: inspectorOrganization.id },

    ...withInspector && !withoutInspector && { is_inspector_exist: 1 },
    ...withoutInspector && !withInspector && { is_inspector_exist: 0 },

    ...withWorkType && !withoutWorkType && { is_work_type_exist: 1 },
    ...withoutWorkType && !withWorkType && { is_work_type_exist: 0 },

    ...mailingId && { mailing_id: mailingId },
    
    ...withAcceptable && acceptance && { acceptance_work_task_id: acceptance }
  }

  return r
}
