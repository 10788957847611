<template>
    <div v-if="items"
         :class="classes.root">
        <!-- Items -->
        <div class="hidden-x">
            <item v-for="item in items"
                  :key="item.key"
                  :item="item"
                  :selection="selection"
                  @select="item => select(item)" />
        </div>

        <!-- Tutorial -->
        <div v-if="hasTutorial"
             class="hidden-x">
            <item id="tutorial-launch"
                  :key="tutorialItem.key"
                  :item="tutorialItem"
                  @click="showTutorial" />
        </div>

        <!-- Toggle -->
        <div class="border-top-1 border-rock h-1.5 f-center cursor-pointer"
             @click="toggle">
            <icon :name="icons.toggle"
                  color="gray-600"
                  class="wh-0.9" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { PERMISSION_LIST } from '@/utils/permissions'

import { intersection } from '@/utils/immutable'

import Item from './MenuItem.vue'

import HomeIcon from '@/components/icons/menu/HomeIcon.vue'
import StructureIcon from '@/components/icons/menu/StructureIcon.vue'
import GprIcon from '@/components/icons/menu/GprIcon.vue'
import DashboardIcon from '@/components/icons/menu/DashboardIcon.vue'
import DefectIcon from '@/components/icons/menu/DefectIcon.vue'
import BuildingOrderIcon from '@/components/icons/menu/BuildingOrderIcon.vue'
import AcceptanceIcon from '@/components/icons/menu/AcceptanceIcon.vue'
import PhotoOrderIcon from '@/components/icons/menu/PhotoOrderIcon.vue'
import DocumentIcon from '@/components/icons/menu/DocumentIcon.vue'
import BimIcon from '@/components/icons/menu/BimIcon.vue'
import PwpIcon from '@/components/icons/menu/PwpIcon.vue'
import SettingsIcon from '@/components/icons/menu/SettingsIcon.vue'
import TutorialIcon from '@/components/icons/BookInformationIcon.vue'
import ApprovalIcon from '@/components/icons/Approval.vue'
import { types } from '@/models/documents';

const to = x => ({ ...x, key: key() })

const unify = items => items.map(x => ({ ...to(x), children: (x.children || []).map(to) }))

export default {
  components: {
    Item
  },
  data() {
    return {
      selection: null,
      unreadTasks: null,
      collapsed: get('menu-collapsed', false, { cookie: true }) === 'true',
      projectMenu: [[
        {
          title: 'Главная',
          iconAsComponent: HomeIcon,
          to: {
            name: 'project.home'
          }
        },
        {
          title: 'Дашборд',
          permissions: 'project_dashboard_show',
          iconAsComponent: DashboardIcon,
          to: {
            name: 'project.about'
          }
        },
        {
          title: 'ГПР',
          permissions: 'project_gpr_show',
          iconAsComponent: GprIcon,
          to: {
            name: 'project.gpr'
          }
        },
        {
          title: 'Структура проекта',
          iconAsComponent: StructureIcon,
          to: {
            name: 'project.photos'
          }
        },
        {
          title: 'Дефекты и нарушения',
          permissions: 'project_tasks_defects_show',
          iconAsComponent: DefectIcon,
          to: {
            name: 'project.tasks.defects'
          }
        },
        {
          title: 'Предписания',
          permissions: 'project_tasks_building_orders_show',
          iconAsComponent: BuildingOrderIcon,
          to: {
            name: 'project.tasks.building.order'
          }
        },
        {
          title: 'Приемка работ',
          permissions: 'project_tasks_acceptance_works_show',
          iconAsComponent: AcceptanceIcon,
          to: {
            name: 'project.tasks.acceptance'
          }
        },
        {
          title: 'Процессы согласования',
          permissions: permissionsAny(
            'project_approval_process_be_chief_project_engineer',
            'project_approval_process_be_document_approver',
            'project_approval_process_be_project_designer'
          ),
          iconAsComponent: ApprovalIcon,
          to: {
            name: 'project.tasks.approval'
          }
        },
        {
          title: 'Заявки на фото 360',
          permissions: 'project_tasks_photo_orders_show',
          iconAsComponent: PhotoOrderIcon,
          to: {
            name: 'project.tasks.photo.order'
          }
        },
        {
          title: 'Среда общих данных',
          permissions: permissionsAny(
            'project_documents_work_documentation_show',
            'project_documents_bim_model_show',
            'project_documents_project_work_production_show',
            'project_documents_executive_documentation_show',
            'project_documents_initial_permitting_documentation_show',
            'project_documents_project_documentation_show',
            'project_documents_other_documentation_show'
          ),
          iconAsComponent: DocumentIcon,
          to: {
            name: 'project.documents',
            query: {
              type: types.WORK_DOCUMENTATION
            }
          }
        },
        {
          title: 'Настройки проекта',
          iconAsComponent: SettingsIcon,
          to: {
            name: 'project.settings'
          }
        }

        // {
        //   icon: 'el-icon-menu',
        //   title: 'HeatMap',
        //   licenceAbility: LICENCE_ABILITIES['show_recognized_objects_statistic_by_licence'],
        //   to: {
        //     name: 'project.heatmap'
        //   }
        // },
        // { icon: 'el-icon-s-marketing', title: 'План/Факт', to: { name: 'project.gantt' } },
        //{
        //  icon: 'el-icon-data-line',
        //  title: 'Дефекты и нарушения',
        //  permissions: permissions('project_tasks_defects_show'),
        //  licenceAbility: LICENCE_ABILITIES['show_tasks_statistic_by_licence'],
        //  to: {
        //    name: 'project.tasks.statistic'
        //  }
        //},
        // { icon: 'el-icon-date', title: 'Календарь задач', to: { name: 'project.calendar' } },
        // { icon: 'el-icon-s-grid', title: 'Ход СМР', to: { name: 'project.jobs.progress' } },
        //{
        //  icon: 'el-icon-help',
        //  title: 'Дашборд (old)',
        //  permissions: PERMISSION_LIST['comments.access'],
        //  to: {
        //    name: 'project.dashboard-old'
        //  }
        //}
      ]].map(unify).flat(),

      mlMenu: [[
        {
          icon: 'el-icon-picture-outline',
          title: 'Версии',
          settings: 'ml_setting_section_enable',
          permissions: PERMISSION_LIST['ml.train'],
          to: {
            name: 'ml.versions'
          }
        },
        {
          icon: 'el-icon-s-data',
          title: 'Статистика',
          settings: 'ml_setting_section_enable',
          permissions: PERMISSION_LIST['logs.ml'],
          to: {
            name: 'ml.statistics'
          }
        },
        {
          icon: 'el-icon-cpu',
          title: 'GPU',
          settings: 'ml_setting_section_enable',
          permissions: PERMISSION_LIST['logs.ml'],
          to: {
            name: 'ml.gpu'
          }
        },
        {
          icon: 'el-icon-data-analysis',
          title: 'История задач на обучение',
          settings: 'ml_setting_section_enable',
          permissions: PERMISSION_LIST['logs.ml'],
          to: {
            name: 'ml.train-tasks.history'
          }
        },
        {
          icon: 'el-icon-receiving',
          title: 'Песочница',
          settings: 'ml_setting_section_enable',
          to: {
            name: 'ml.sandbox'
          }
        }
      ]].map(unify).flat(),

      managementUsersMenu: [[
        {
          icon: 'el-icon-user',
          title: 'Пользователи',
          permissions: PERMISSION_LIST['users.management'],
          to: {
            name: 'management.users'
          }
        },
        {
          icon: 'el-icon-s-order',
          title: 'История действий',
          permissions: PERMISSION_LIST['logs.users'],
          to: {
            name: 'management.users.logs'
          }
        },
        {
          icon: 'el-icon-user-solid',
          title: 'Роли',
          permissions: PERMISSION_LIST['roles.management'],
          to: {
            name: 'management.roles'
          }
        },
        {
          icon: 'el-icon-s-claim',
          title: 'Лицензии',
          permissions: PERMISSION_LIST['licence.management'],
          to: {
            name: 'management.licenses'
          }
        }
      ]].map(unify).flat(),

      lkMenu: [[
        {
          icon: 'el-icon-user-solid',
          title: 'Профиль',
          to: {
            name: 'lk.profile'
          }
        },
        {
          icon: 'el-icon-bell',
          title: 'Уведомления',
          to: {
            name: 'lk.notifications'
          }
        }
      ]].map(unify).flat()
    }
  },
  computed: {
    ...mapGetters('auth', ['licence']),

    classes() {
      return {
        root: {
          'h-full rows-minmax-min bg-el-side': true,
          'w-3': this.collapsed,
          'w-18': !this.collapsed
        }
      }
    },

    icons() {
      return {
        toggle: this.collapsed ? 'corner-right' : 'corner-left'
      }
    },

    items() {
      const { projectId } = this.$route.params
      const { leftSideMenu } = this.$route.meta

      const is = leftSideMenu && leftSideMenu.on && leftSideMenu.name

      let items

      items ||= is === 'project' && this.projectMenu
      items ||= is === 'ml' && this.mlMenu
      items ||= (is === 'users' || is === 'licenses') && this.managementUsersMenu
      items ||= is === 'lk' && this.lkMenu

      items = items && items.filter(item => item.to?.name === 'project.gantt' ? projectId === process.env.VUE_APP_DEMO_PROJECT_ID : true)
      items = items && items.filter(item => item.to?.name === 'project.jobs.progress' ? projectId === process.env.VUE_APP_PROJECT_DEMO_ID : true)

      return items
    },

    tutorialItem() {
      return {
        key: 'tutorial',
        iconAsComponent: TutorialIcon,
        title: 'Обучающий тур по странице'
      }
    },

    hasTutorial() {
      return !!this.$route.meta?.tutorial
    }
  },
  updated() {
    const serializeQuery = query => query && JSON.stringify(query) || ''

    const matched = this.$route.matched.map(({ name, meta: { query } = {} }) => name + serializeQuery(query))

    const item = (this.items || [])
      .find(x => intersection(matched, [
        x.to?.name + serializeQuery(x.to?.query), 
        ...x.children ? x.children.map(({ to, query }) => to.name + serializeQuery(query)) : []
      ].filter(x => x)).length)

    item && this.select(item)
  },
  mounted() {
    if (!this.selection) {
      this.selection = this.projectMenu.find(x => x.to.name === this.$route.name)
    }
  },
  methods: {
    select(item) {
      this.selection = item
    },

    toggle() {
      set('menu-collapsed', this.collapsed = !this.collapsed, { cookie: true })
    },

    showTutorial() {
      this.$route.meta?.tutorial?.show?.()
    }
  }
};
</script>
<style lang="scss">
.el-menu {
  border-right: none !important;
}
</style>
