<template>
    <div class="abs t-4 r-1 w-22 bg-el-dark depth-100 rounded-md fs-0.75">
        <div class="f-x-between f-y-center fs-0.9 text-white _px-1 _py-0.5 border-bottom-1 border-gray-600">
            <p class="_m-0">
                {{ labels.title }}
            </p>
            <el-button type="text"
                       class="text-white"
                       icon="el-icon-close"
                       @click="$emit('close')" />
        </div>
        <el-form
            class="_py-0.75 _px-1 space-y-0.5"
            label-position="left"
            label-width="120px">
            <tech-room-field v-model="value"
                             label="Помещение"
                             name="value"
                             required
                             :readonly="!hasAccess({ permissions:'project_structure_edit' })" />
            <input-field v-if="value"
                         :value="value.name"
                         label="Имя" 
                         size="mini"
                         readonly />
            <color-field v-if="value"
                         :value="getTechRoomColor(value)"
                         :options="colorOptions"
                         label="Цвет"
                         readonly />
            <access permissions="project_structure_edit"
                    hidable>
                <div class="f-col">
                    <el-button type="primary"
                               class="_mb-0.5"
                               size="mini"
                               :disabled="!value"
                               @click="handleSave">
                        {{ labels.submit }}
                    </el-button>
                    <el-button type="danger"
                               class="m-0"
                               size="mini"
                               @click="$emit('close')">
                        {{ labels.cancel }}
                    </el-button>
                </div>
            </access>
        </el-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { techRoomColorsWithLabel } from '@/utils/colors';
import { getTechRoomColor } from '@/models/techRooms';

import InputField from '@/components/fields/InputField';
import ColorField from '@/components/fields/ColorField';
import TechRoomField from '@/components/fields/TechRoomField';

export default {
  components: {
    InputField,
    ColorField,
    TechRoomField
  },

  props: {
    feature: { type: Object, default: null }
  },

  data() {
    return {
      value: null,

      getTechRoomColor
    }
  },

  computed: {
    ...mapGetters('account', ['hasAccess']),

    labels() {
      return {
        title: 'Добавить помещение',
        submit: 'Сохранить изменения',
        cancel: 'Отменить'
      }
    },

    colorOptions() {
      return Object.entries(techRoomColorsWithLabel)
        .map(([, { value, label }]) => ({ value, label }))
    }
  },

  watch: {
    feature: {
      immediate: true,
      handler(val) {
        if (val && val.get('techRoom')) {
          this.value = val.get('techRoom')
        } else {
          this.value = null
        }
      }
    }
  },

  methods: {
    handleSave() {
      const polygon = this.feature.getGeometry().getCoordinates()[0]

      this.$emit('save', this.value.id, polygon)
    }
  }
}
</script>
