export function innerFolderStructureByGroupType(folders, group) {
  return folders.map((x) => ({
    ...x, 
    idWithGroup: `${group}-${x.id}`,
    group,
    children: x.children.length ? innerFolderStructureByGroupType(x.children, group) : x.children
  }))
}

export function getFolderParents(folders, id) {
  return folders.reduce((arr, x) => {
    if (x.id === id) return [ x.id ]

    if (x.children && x.children.length) {
      const parents = getFolderParents(x.children, id)
      
      if (parents.length) {
        arr.push(x.id, ...parents)
      }
    }
    
    return arr
  }, [])
}
  