export default axios => ({
  getPayments: ({ filter, sort, page, size }) => axios.get('admin/payments', {
    params: {
      filter,
      include: [
        'object.project.avatar',
        'subject.organization',
        'subject.users.user',
        'recipient.organization'
      ],
      sort: sort || '-created_at',
      page: {
        number: page,
        size
      }
    }
  }),

  getPaymentsCount: ({ filter }) => axios.get('admin/payments/count', {
    params: {
      filter
    }
  }),

  updatePayment: ({ payment, status, project }) => axios.patch(`admin/payments/${payment.id}`, {
    status,
    project_id: project?.id
  }),

  blockPayment: ({ payment }) => axios.post(`admin/payments/${payment.id}/block`),
  unblockPayment: ({ payment }) => axios.post(`admin/payments/${payment.id}/unblock`)
})
