<template>
    <el-row>
        <el-card shadow="never"
                 :body-style="{padding:'6px'}"
                 class="card-color--comments">
            <el-row style="max-height: 564px; overflow: auto"
                    class="comments-wrapper my-2 p-4">
                <el-col v-for="message in taskMessages"
                        :key="message.id">
                    <comment-container :message="message"
                                       @edit-message="handleEditMessage" />
                </el-col>
            </el-row>
        </el-card>
        <el-row type="flex"
                :gutter="16"
                class="mt-4">
            <el-col :span="18">
                <el-input v-model="text"
                          :disabled="messageSending"
                          type="textarea"
                          :autosize="{minRows:2}"
                          placeholder="Введите текст сообщения..."
                          @keydown.ctrl.enter.native="handleSendMessage(message)" />
            </el-col>
            <el-col :span="6">
                <el-button type="primary"
                           class="width-full"
                           :disabled="messageSending || text.trim().length===0"
                           @click="handleSendMessage">
                    Отправить
                </el-button>
            </el-col>
        </el-row>
    </el-row>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';
import { actionable, resourceable } from '@/store/connectors'

import CommentContainer from './CommentContainer.vue';

export default {
  components: { CommentContainer },
  mixins: [
    resourceable({ on: 'tasks', name: 'taskMessages' }),
    actionable({ on: 'tasks', name: 'setTaskMessages' })
  ],
  props: {
    task: { type: Object, default: null }
  },
  data: () => ({
    text: '',
    messageId: null,
    messageSending: false,
    page: 1
  }),
  computed: {
    ...mapGetters({profile: 'auth/profile'})
  },
  mounted() {
    this.task && this.fetchTaskMessages({ task: this.task, withUser: true })
  },
  methods: {
    ...mapActions({
      sendMessage: 'tasks/sendMessage',
      updateMessage: 'tasks/updateMessage'
    }),

    handleSendMessage() {
      this.messageSending = true;
      let callback = () => {
        if (this.messageId) {
          return this.updateMessage({
            taskId: this.$route.params.taskId,
            messageId: this.messageId,
            payload: {text: this.text.trim()}
          });
        } else {
          return this.sendMessage({
            taskId: this.$route.params.taskId,
            payload: {text: this.text.trim()}
          });
        }
      };
      callback().then((response) => {
        if (this.messageId) {
          let index = this.taskMessages.findIndex(item => item.id === this.messageId);
          if (index !== -1) {
            this.taskMessages[index].text = this.text;
          }
          this.setTaskMessages(this.taskMessages)
        } else {
          this.taskMessages.push({
            id: response.data.id,
            text: this.text,
            user: {
              name: this.profile.name,
              email: this.profile.email,
              id: this.profile.id
            },
            created_at: moment().utc().format('YYYY-MM-DD HH:mm:ss')
          });
          this.setTaskMessages(this.taskMessages)
        }
        this.text = '';
        this.messageId = null;
      }).catch(() => {
        this.$notify({
          type: 'danger',
          message: 'Произошла ошибка'
        });
      }).finally(() => {
        this.messageSending = false;
      });
    },
    handleEditMessage(message) {
      this.messageId = message.id;
      this.text = message.text;
    }
  }
};
</script>
